
import { nextTick, ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { MGensee } from "@/core/interface/gensee.d";
import useRequiredInject from "@/composable/useRequiredInject";
import ThumbsUpAni from '@/core/utils/ThumbsUpAni'
export default {
  name: "Like",
  components: {
    
  },
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    let thumbsUpAni: ThumbsUpAni
    gsSdkService.on(gsSdkService.EventEnum.onUpdatePraiseTotalSuccess, (e) =>{
      
    })
    nextTick(() =>{
      thumbsUpAni = new ThumbsUpAni('canvas')
    })
    function doLike(){
      thumbsUpAni && thumbsUpAni.start()
      gsSdkService.submitPraise(1)
    }

    return {
      doLike
    };
  },
};
