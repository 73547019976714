<template>
  <div class="list" v-if="!loading && !isEmpty()">
    <div class="list-item" 
      v-for="vodItem in vods" 
      :key="vodItem.timeId"
      @click="goToVideoPage(vodItem)"
      :class="{'list-item_active': vodItem.timeId === timeId && timeId}">
      <div class="left-wrapper">
        <img src="@/assets/images/icon-live.png" alt="live icon" />
      </div>
      <div class="right-wrapper">
        <div class="task-title">
          {{ vodItem.timeName }}
        </div>
        <div class="detail-wrapper">
          <div>
            <span class="sub-title">{{ fomatDate(vodItem) }}</span>
            <span class="tag">出勤{{ vodItem.attendance }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="loading-wrapper" v-if="loading">
    <a-spin />
  </div>

  <div class="empty-wrapper" v-if="!loading && isEmpty()">
      <a-empty :description="'暂无回放'" />
  </div>
</template>

<script lang="ts">
export interface IPlayBackVideo {
  timeName: string;
  timeId: string;
  startTime: number;
  endTime: number;
  attendance: string;
  url: string;
}

import { defineComponent, ref } from "vue";
import { getRecordList } from "@/api/live.api";
import { format, isSameDay } from "date-fns";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default defineComponent({
  name: "PlaybackVideo",
  setup() {
    const liveServie = useRequiredInject(LiveServiceKey);
    const vods = ref<IPlayBackVideo[]>([]);
    const loading = ref(true);
    // 获取回放记录列表
    getRecordList(liveServie.lessonPlanId.value, liveServie.studentId).then(res => {
      loading.value = false;
      if (res.status === 200) {
        if(res.data && res.data.length){
          // 排除没回放的数据
          res.data = res.data.filter((item: any) => item.url)
        }
        vods.value = res.data || [];
      }
    }).catch(err => {
      loading.value = false;
    })
    function goToVideoPage(video: IPlayBackVideo) {
      location.href = video.url
    }

    function isEmpty(): boolean {
      return vods.value.length === 0;
    }

    function fomatDate(video: IPlayBackVideo): string {
      const { startTime, endTime } = video;
      if (!startTime || !endTime) {
        return '--';
      }
      if (isSameDay(startTime, endTime)) {
        const dayStr = format(startTime, 'M月d日');
        const startTimeStr = format(startTime, 'HH:mm');
        const endTimeStr = format(endTime, 'HH:mm');
        return `${dayStr} ${startTimeStr}~${endTimeStr}`;
      } else {
        const startTimeStr = format(startTime, 'M月d日 HH:mm');
        const endTimeStr = format(endTime, 'M月d日 HH:mm');
        return `${startTimeStr}~${endTimeStr}`;
      }
    }
    return {
      vods,
      loading,
      goToVideoPage,
      isEmpty,
      fomatDate,
      timeId: liveServie.lessonTimeId.value
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.list {
  width: 100%;
  height: 100%;
  padding: 18px;
  overflow: auto;

  .list-item {
    display: flex;
    cursor: pointer;
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 12px;
    margin-bottom: 18px;

    .left-wrapper {
      flex: 0 0 20px;
      width: 20px;
    }

    .right-wrapper {
      flex: 1;
      position: relative;
    }

    .task-title {
      font-size: 14px;
      color: #3a3c42;
    }

    .detail-wrapper {
      display: flex;
      justify-content: space-between;

      .tag {
        display: inline-block;
        padding: 0 4px;
        height: 18px;
        line-height: 18px;
        text-align: center;

        font-size: 12px;
        color: #00ab84;
        background-color: rgba(0, 171, 132, 0.1);
        border-radius: 3px;
      }

      .sub-title {
        display: inline-block;
        color: #a3abb8;
        font-size: 12px;
        margin-right: 18px;
      }
    }
    .playing{
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      background-color: #00ab84;
      color: #fff;
      border-radius: 3px;
      padding: 0 4px;
      height: 18px;
      line-height: 18px;
    }
    &_active{
      border: 2px #00ab84 solid;
    }
  }

  .selected {
    border: 2px solid #00ab84;
  }
}

.loading-wrapper {
  width: 100%;
  text-align: center;
  padding: 18px;
}

.empty-wrapper {
    width: 100%;
  text-align: center;
  padding: 18px;
}
</style>
