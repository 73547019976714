<template>
  <div class="video-progress-wrapper">
    <a-slider
      :max="duration"
      :tooltipVisible="false"
      v-model:value="progressValue"
      @change="progressChange"
      @afterChange="progressAfterChange"
    />
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { CurrentVodTimeStorage } from "@/services/cache.service";
import { LiveService, LiveServiceKey } from '@/services/live.service';

export default {
  name: "VideoProgress",
  setup() {
    const progressValue = ref(0);
    const duration = ref<number>(1000);
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const liveServie = useRequiredInject(LiveServiceKey);

    gsSdkService?.on("onFileDuration", function (event) {
      const _duration = event.data.duration;
      duration.value = Math.ceil(Number(_duration / 1000));
    });
    // 当前播放时间回调
    gsSdkService?.on("onPlayheadTime", function (event) {
      const playheadTime = event.data.playheadTime;
      const formatPlayheadTime = Math.ceil(Number(playheadTime / 1000));
      progressValue.value = formatPlayheadTime;
      CurrentVodTimeStorage.set(formatPlayheadTime, liveServie.ownerid.value);
    });
    // 用户手动跳跃播放时间完毕后回调
    gsSdkService?.on("onSeekCompleted", function (event) {
      gsSdkService?.getPlayNow();
      gsSdkService.submitChatSegment()
    });

    const progressChange = (val: number) => {
      gsSdkService?.clearPlayNowTimerHandler();
    };

    const progressAfterChange = (val: number) => {
      gsSdkService?.seek(val * 1000);
    };
    return {
      progressValue,
      duration,
      progressChange,
      progressAfterChange,
    };
  },
};
</script>

<style scoped lang="less">
@height: 6px;
.video-progress-wrapper {
  width: 100%;
  height: @height;

  :deep .ant-slider {
    margin: 0;
    padding: 0;
    height: @height;

    .ant-slider-handle {
      display: none;
    }

    .ant-slider-rail {
      background-color: #666666;
    }
  }
}
</style>
