import useRequiredInject from "./useRequiredInject";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { Ref, ref } from "vue";
/**
 * 视频播放状态
 * @returns {Ref}
 */
export default function usePlayState() {
  const gsSdkService = useRequiredInject(GsSdkServiceKey);
  const playState = ref(gsSdkService?.PlayStateEnum.unStart)
  if(!gsSdkService.on) return playState
  gsSdkService?.on(gsSdkService.LiveLifecycleEvent.onStatus, event =>{
    if(event.data.type === gsSdkService.SDKStatusEnum.pending){
      // 缓冲中
      playState.value = gsSdkService.PlayStateEnum.pending
    }
  })
  gsSdkService?.on(gsSdkService.LiveLifecycleEvent.onStop, ()=>{
    playState.value = gsSdkService.PlayStateEnum.stop
  })
  gsSdkService?.on(gsSdkService.LiveLifecycleEvent.onPlay, ()=> {
    playState.value = gsSdkService.PlayStateEnum.play
  })
  gsSdkService?.on(gsSdkService.LiveLifecycleEvent.onStart, ()=> {
    playState.value = gsSdkService.PlayStateEnum.play
  })
  gsSdkService?.on(gsSdkService.LiveLifecycleEvent.onPause, ()=> {
    playState.value = gsSdkService.PlayStateEnum.pause
  })

  return playState
}
