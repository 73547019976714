import { getItem, setItem } from "@/core/utils/storage";

/** 当前回放播放的时间 以ownerid做区分，存储在localstorage */
export const CurrentVodTimeStorage = {
  key: "CurrentVodTime",
  get: function(ownerid:string): number {
    if(!ownerid) return 0
    const val = getItem("local", this.key + '_' + ownerid);
    return val ? Number(val) : 0;
  },
  set: function(time: number, ownerid: string) {
    if(!ownerid) return
    setItem("local", this.key + '_' + ownerid, time);
  }
}