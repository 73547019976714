<template>
  <div class="live-container">
    <section class="hearder-wrapper">
      <div class="header-title">
        <Tag color="#FC3F4C" v-if="isLive">
          <template #icon v-if="playStateText === '缓冲中...'">
            <a-spin :indicator="indicator"></a-spin>
          </template>
          {{ playStateText }}
        </Tag>
        {{ coursePacketName }}
        <span class="divider">></span>
        <span class="sub-title">{{ lessonTimeName }}</span>
      </div>
      <div class="avatar-container">
        <a-avatar :size="24" :src="avatar" class="avatar">
          <template #icon><UserOutlined /></template>
        </a-avatar>
        <span>{{ username }}</span>
      </div>
    </section>
    <!-- <div class="teacher">
      <a-avatar :size="24" :src="avatar" class="avatar">
        <template #icon><UserOutlined /></template>
      </a-avatar>
      <span>{{ username }}</span>
    </div> -->
    <section class="section" style="background: transparent !important">
      <div ref="mouseContainerRef">
        <div
          :class="[
            'live-wrapper',
            viewType === 'video' ? 'left-display' : 'right-display',
            viewType === 'video' && fullscreen ? 'live-fullsreen' : '',
          ]"
        >
          <GsVideoLive v-if="refresh"/>
        </div>
        <div
          :class="[
            'doc-wrapper',
            viewType === 'doc' ? 'left-display' : 'right-display',
            viewType === 'doc' && fullscreen ? 'live-fullsreen' : '',
          ]"
        >
          <GsDoc v-if="refresh"/>
        </div>
        <div
          :class="[
            'live-tools',
            fullscreen ? 'video-tools-fullsreen' : '',
            fullscreen && mouseIdle ? 'video-tools-fullsreen-hide' : '',
          ]"
        >
          <VideoTools />
        </div>
      </div>
      <div class="sider-wrapper">
        <div
          style="height: 10px; background-color: rgba(255, 255, 255, 0.1)"
        ></div>
        <div class="chat-container">
          <FunctionArea />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, h, onMounted, ref } from "vue";
import GsVideoLive from "@/components/GsVideoLive.vue";
import GsDoc from "@/components/GsDoc.vue";
import FunctionArea from "@/components/FunctionArea.vue";
import {UserOutlined, LoadingOutlined} from "@ant-design/icons-vue";
import VideoTools from "@/components/VideoTools/VideoTools.vue";
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useDetectMouseMove from "@/composable/useDetectMouseMove";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { LiveServiceKey } from "@/services/live.service";
import { message, Tag } from "ant-design-vue";
import useRequiredInject from "@/composable/useRequiredInject";
import usePlayState from '@/composable/usePlayState'
import { Mode } from "@/core/constant";

export default defineComponent({
  name: "Live",
  components: {
    GsVideoLive,
    GsDoc,
    FunctionArea,
    UserOutlined,
    VideoTools,
    Tag
  },
  setup() {
    const videoToolsService = useRequiredInject(VideoToolsServiceKey);
    const liveServie = useRequiredInject(LiveServiceKey);
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const playState = usePlayState()
    const isLive = ref(liveServie.mode.value === Mode.live)
    const refresh = ref(false)
    
    gsSdkService.registerEvent(liveServie.mode.value);
    gsSdkService.on("onStatus", function (event) {
      const type = Number(event.data.type);
      const statusDesc = gsSdkService.getStatus(liveServie.mode.value, type);
      if (statusDesc && type !== gsSdkService.SDKStatusEnum.pendingWithFirst && type !== gsSdkService.SDKStatusEnum.pending) {
        message.warn(statusDesc);
      }
    });

    gsSdkService.on("onAPIError", function (event) {
      console.log('[onAPIError]', event);
      if(event?.data.api === 'submitChat' && /submitChat too frequent/.test(event?.data.explain)){
        message.warn('发送过于频繁，请稍后再试')
      }
    });

    gsSdkService?.on("onDataReady", function (event) {
      console.log('%c [onDataReady]SDK加载完毕，所有API生效', 'color: green;font-size: 20px')
      if (liveServie.mode.value === "vod") {
        gsSdkService.getPlayNow();
        gsSdkService.submitChatHistory();
      } else {
        gsSdkService.requireNetSettings();
      }
      registerLiveEvent()
    });
    // 注册相关事件回调
    function registerLiveEvent(){
      let registerEventList: string[] = []
      Object.keys(gsSdkService.EventEnum).forEach((eventName: string) =>{
        gsSdkService.on(eventName, function(event){
          const message = `收到信息: eventName: ${eventName}; DATASET: ${ Object.prototype.toString.call(event) === '[object Object]' ? JSON.stringify(event) : event?.toString ? event?.toString() : event }`
          console.log(message);
        })
        registerEventList.push(eventName)
      })
      console.log(`%c [registerLiveEvent] 注册事件: ${registerEventList}`, 'color: green;font-size: 14px');
      
    }
    // 返回当前直播播放状态
    const playStateText = computed(()=>{
      if(!isLive.value) return ''
      const PlayStateEnum = gsSdkService.PlayStateEnum
      return playState.value === PlayStateEnum.play ? '播放中...' : 
        playState.value === PlayStateEnum.stop ? '停止' : 
        playState.value === PlayStateEnum.pause ? '暂停' : 
        playState.value === PlayStateEnum.unStart ? '即将开始' : 
        playState.value === PlayStateEnum.pending ? '缓冲中...' : ''
    })
    // 自定义加载
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '14px',
        color: '#fff',
        fontWeight: 'bold'
      },
      spin: !0
    })

    const { mouseContainerRef, mouseIdle } = useDetectMouseMove();

    onMounted(()=>{
      refresh.value = true
      // gsSdkService.refreshChannel()
    })

    return {
      viewType: videoToolsService.changeViewRef,
      fullscreen: videoToolsService.fullscreenRef,
      mouseContainerRef,
      mouseIdle,
      username: liveServie.username,
      coursePacketName: liveServie.coursePacketName,
      lessonTimeName: liveServie.lessonTimeName,
      avatar: liveServie.avatar,
      playStateText,
      indicator,
      isLive,
      refresh
    };
  },
});
</script>

<style lang="less">
.live-container {
  height: 100%;
  flex: auto;
  position: relative;
  margin: 0 auto;
  .section{
    height: 100%;
    max-width: 1510px;
    min-width: 1010px;
    min-height: 540px;
    max-height: 850px;
    position: relative;
    background: transparent !important;
    flex: auto;
    margin: 0 auto;
  }
  .hearder-wrapper {
    max-width: 1510px;
    min-width: 1010px;
    height: 44px;
    line-height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin: 0 auto;
    .header-title {
      font-size: 14px;
      .divider {
        margin: 0 4px;
      }
      img {
        width: 88px;
        margin-right: 10px;
      }
      .sub-title{
        color: rgba(255, 255, 255, .7);
      }
    }
    .avatar-container{
      border-radius: 1000px;
      background-color: #00AB84;
      height: 24px;
      line-height: 24px;
      padding-right: 8px;
      font-size: 12px;
      .avatar{
        margin-right: 6px;
      }
    }
  }
  .teacher{
    position: absolute;
    left: 0;
    top: 54px;
    left: 18px;
    z-index: 10;
    border-radius: 1000px;
    background-color: rgba(0, 0, 0, 0.5);
    height: 24px;
    line-height: 24px;
    padding-right: 8px;
    font-size: 12px;
    color: #fff;
    .avatar{
      margin-right: 6px;
    }
  }
  .live-wrapper {
    // width: calc(100% - 300px);
    // height: calc(100% - 54px);
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    // left: 0;
    transition: all 0.3s;
    &.live-fullsreen{
      background: url('~@/assets/images/bg.png') no-repeat center center;
      background-color: rgba(0, 0, 0, 0.4);
      background-size: cover;
      video{
        background-color: transparent;
      }
    }
    video{
      background-color: #000;
    }
  }

  .live-tools {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: calc(100% - 300px);
    transition: all 0.3s;
  }

  .doc-wrapper {
    position: absolute;
    top: 0;
    transition: all 0.3s;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    :deep {
      .wrapper {
        height: 100%;
      }
    }
  }

  .sider-wrapper {
    position: absolute;
    height: calc(100% - 215px);
    right: 0;
    bottom: 0;
    @width: 300px;
    flex: 0 0 @width!important;
    max-width: @width!important;
    min-width: @width!important;
    width: @width!important;
    .chat-container {
      height: calc(100% - 10px);
      background-color: #fff;
    }
  }
}

.right-display {
  right: 0;
  width: 300px;
  height: 220px;
}

.left-display {
  width: calc(100% - 300px);
  height: 100%;
  left: 0;
}

.live-fullsreen {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  height: 100%;
  z-index: 99 !important;
}

.video-tools-fullsreen {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 100 !important;
}
.video-tools-fullsreen-hide {
  bottom: -80px !important;
}
#gs-player-error-info1{
  display: flex;
  justify-content: flex-end;
}
#docDefaultBgImg{
  object-fit: contain;
}
</style>
