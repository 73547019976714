
import { defineComponent, ref, watch } from "vue";
import CheckCircleFilled from "@ant-design/icons-vue/CheckCircleFilled";
import { getTaskList } from "@/api/live.api";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export interface ITask {
  taskType: string;
  taskName: string;
  taskId: string;
  captionTitle: string;
  url: string;
  status: string;
}

export default defineComponent({
  name: "Tasks",
  components: {
    CheckCircleFilled,
  },
  setup() {
    const liveServie = useRequiredInject(LiveServiceKey);
    const tasks = ref<ITask[]>([]);
    const loading = ref(false);
    const hadClickGetButton = ref(false)//是否已经点击了获取按钮
    const getTaskName = (taskType: string) => {
      /*
    阅读任务(0)、案例任务(1)、作业任务(2)、实训任务(3)、考试任务(4)、实战任务(5)、问卷(6)、测评(7)
    * */
      switch (Number(taskType)) {
        case 0:
          return "阅读";
        case 1:
          return "案例";
        case 2:
          return "作业";
        case 3:
          return "实训";
        case 4:
          return "考试";
        case 5:
          return "实战";
        case 6:
          return "问卷";
        case 7:
          return "测评";
        default:
          return "--";
      }
    };
    // 获取任务列表
    function getTask(action?: boolean): void {
      loading.value = true;
      getTaskList(
        liveServie.lessonTimeId.value,
        liveServie.orgCode,
        liveServie.studentId
      )
        .then((res) => {
          loading.value = false;
          if (res.status === 200) {
            tasks.value = res.data || [];
          }
          if(action){
            hadClickGetButton.value = true
            setTimeout(() => {
              hadClickGetButton.value = false
            }, 2e3);
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }

    function goToTaskPage(task: ITask) {
      window.open(task.url, "_blank");
    }
    function isEmpty(): boolean {
      return tasks.value.length === 0;
    }

    getTask();

    return {
      tasks,
      getTaskName,
      goToTaskPage,
      isEmpty,
      loading,
      getTask,
      hadClickGetButton
    };
  },
});
