
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useRequiredInject from '@/composable/useRequiredInject';

export default {
  name: "FullScreen",
  setup() {
    const videoToolsService = useRequiredInject(VideoToolsServiceKey);
    const toggleFullscreen = () => {
      const fullscreen = videoToolsService.fullscreenRef;
      fullscreen.value = !fullscreen.value;
    };
    return {
      toggleFullscreen,
      fullscreen: videoToolsService.fullscreenRef,
    };
  },
};
