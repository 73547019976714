import axios from "axios";
// import config from '@/config'
import { stringify } from "qs";
// import { getToken } from '@/libs/util'

import { message } from "ant-design-vue";

import { environment } from "@/environments";

// const publicPath = config.publicPath;

const Axios = axios.create();
// Axios.defaults.baseURL = publicPath
Axios.defaults.timeout = 0;
// Axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

Axios.defaults.transformRequest = [
  (data) => (typeof data === "object" ? stringify(data) : data),
];

// 请求拦截
Axios.interceptors.request.use(
  (cf) => {
    // cf.headers['login-token'] = getToken()
    if (!cf.url?.startsWith("https://") || !cf.url?.startsWith("http://")) {
      cf.url = environment.SERVER_URL + cf.url;
    }
    return cf;
  },
  (err) => {
    message.error({
      content: "请求超时！",
    });
    return Promise.reject(err);
  }
);

// 响应拦截
Axios.interceptors.response.use(
  
  (res) => {
    
    const code = res.data.code;
    const msg = res.data.msg;
    if (code === 205) {
      message.error({
        content: msg,
      });
    } else if (code === 404) {
      message.error({
        content: msg,
      });
    } else if (code === 405) {
      message.error({
        content: msg,
      });
    } else if (code === 503) {
      message.error({
        content: msg,
      });
    } else if (code === 500) {
      message.error({
        content: msg,
      });
    } else if (code === 202 || code === 407) {
      // 登录失效，调转登录
      // ToolsUtil.jumpLogin()
    }
    return res.data;
  },
  (err) => {
    message.error({
      content: err.message || "服务器出错啦！",
    });
    return Promise.reject(err);
  }
);

export default Axios;
