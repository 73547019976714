
import { computed, defineComponent, h, onMounted, ref } from "vue";
import GsVideoLive from "@/components/GsVideoLive.vue";
import GsDoc from "@/components/GsDoc.vue";
import FunctionArea from "@/components/FunctionArea.vue";
import {UserOutlined, LoadingOutlined} from "@ant-design/icons-vue";
import VideoTools from "@/components/VideoTools/VideoTools.vue";
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useDetectMouseMove from "@/composable/useDetectMouseMove";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { LiveServiceKey } from "@/services/live.service";
import { message, Tag } from "ant-design-vue";
import useRequiredInject from "@/composable/useRequiredInject";
import usePlayState from '@/composable/usePlayState'
import { Mode } from "@/core/constant";

export default defineComponent({
  name: "Live",
  components: {
    GsVideoLive,
    GsDoc,
    FunctionArea,
    UserOutlined,
    VideoTools,
    Tag
  },
  setup() {
    const videoToolsService = useRequiredInject(VideoToolsServiceKey);
    const liveServie = useRequiredInject(LiveServiceKey);
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const playState = usePlayState()
    const isLive = ref(liveServie.mode.value === Mode.live)
    const refresh = ref(false)
    
    gsSdkService.registerEvent(liveServie.mode.value);
    gsSdkService.on("onStatus", function (event) {
      const type = Number(event.data.type);
      const statusDesc = gsSdkService.getStatus(liveServie.mode.value, type);
      if (statusDesc && type !== gsSdkService.SDKStatusEnum.pendingWithFirst && type !== gsSdkService.SDKStatusEnum.pending) {
        message.warn(statusDesc);
      }
    });

    gsSdkService.on("onAPIError", function (event) {
      console.log('[onAPIError]', event);
      if(event?.data.api === 'submitChat' && /submitChat too frequent/.test(event?.data.explain)){
        message.warn('发送过于频繁，请稍后再试')
      }
    });

    gsSdkService?.on("onDataReady", function (event) {
      console.log('%c [onDataReady]SDK加载完毕，所有API生效', 'color: green;font-size: 20px')
      if (liveServie.mode.value === "vod") {
        gsSdkService.getPlayNow();
        gsSdkService.submitChatHistory();
      } else {
        gsSdkService.requireNetSettings();
      }
      registerLiveEvent()
    });
    // 注册相关事件回调
    function registerLiveEvent(){
      let registerEventList: string[] = []
      Object.keys(gsSdkService.EventEnum).forEach((eventName: string) =>{
        gsSdkService.on(eventName, function(event){
          const message = `收到信息: eventName: ${eventName}; DATASET: ${ Object.prototype.toString.call(event) === '[object Object]' ? JSON.stringify(event) : event?.toString ? event?.toString() : event }`
          console.log(message);
        })
        registerEventList.push(eventName)
      })
      console.log(`%c [registerLiveEvent] 注册事件: ${registerEventList}`, 'color: green;font-size: 14px');
      
    }
    // 返回当前直播播放状态
    const playStateText = computed(()=>{
      if(!isLive.value) return ''
      const PlayStateEnum = gsSdkService.PlayStateEnum
      return playState.value === PlayStateEnum.play ? '播放中...' : 
        playState.value === PlayStateEnum.stop ? '停止' : 
        playState.value === PlayStateEnum.pause ? '暂停' : 
        playState.value === PlayStateEnum.unStart ? '即将开始' : 
        playState.value === PlayStateEnum.pending ? '缓冲中...' : ''
    })
    // 自定义加载
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '14px',
        color: '#fff',
        fontWeight: 'bold'
      },
      spin: !0
    })

    const { mouseContainerRef, mouseIdle } = useDetectMouseMove();

    onMounted(()=>{
      refresh.value = true
      // gsSdkService.refreshChannel()
    })

    return {
      viewType: videoToolsService.changeViewRef,
      fullscreen: videoToolsService.fullscreenRef,
      mouseContainerRef,
      mouseIdle,
      username: liveServie.username,
      coursePacketName: liveServie.coursePacketName,
      lessonTimeName: liveServie.lessonTimeName,
      avatar: liveServie.avatar,
      playStateText,
      indicator,
      isLive,
      refresh
    };
  },
});
