<template>
  <div class="chat-wrapper">
    <div class="chat-tools">
      <EmotionTool @emotionChange="chooseEmotion" />
      <!-- 问答：先隐藏，因为这个是之前自考赛道提的需求 -->
      <!-- <RegularSpeech @speechChange="chooseSpeech" /> -->
      <div class="seei" @click="toggleSeeMe" :enable="~~seeMe">
        <img :src="require(seeMe ? '@/assets/images/eyes_active.png' : '@/assets/images/eyes@2x.png')" />
        只看我
      </div>
    </div>
    <div
      class="chat-edit-wrapper"
      :placeholder="hasContent ? '' : ''"
    >
      <div
        class="chat-edit"
        contenteditable="true"
        ref="chatContentTpl"
        @input="editorChange"
        @keyup.enter.prevent="sendChatContent"
        @paste="onTextareaPaste" 
      ></div>
    </div>

    <div class="chat-action">
      <a-button type="primary" @click="sendChatContent" :disabled="!hasContent"
        >发送</a-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import EmotionTool from "./EmotionTool.vue";
// import RegularSpeech from "./RegularSpeech.vue";
import { ChatRecordServiceKey } from "../services/chat-record.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { LiveServiceKey } from "@/services/live.service";
const xss = require("xss");
const xssFilterAllHtml = new xss.FilterXSS({
  css: false,
  whiteList: [],
  stripIgnoreTag: true
})
export default defineComponent({
  name: "ChatInput",
  components: {
    EmotionTool,
    // RegularSpeech,
  },
  setup(props, { emit }) {
    const liveService = useRequiredInject(LiveServiceKey);
    const chatRecordService = useRequiredInject(ChatRecordServiceKey);
    const seeMe = ref(liveService.seeMe.value)
    const contentChange = (content: string, innerText: string) => emit("contentChange", content, innerText);
    const sendChatContent = () => {
      const divEle = chatRecordService.chatContentTpl.value;
      if (divEle) {
        const html = divEle.innerHTML;
        contentChange(html, divEle.innerText);
      }
    };

    // 光标处插入内容
    function insertHtmlByRange(divEle: HTMLDivElement, insertEle: any): void {
      divEle?.focus();
      let sel: any, range: any;
      const doc: any = document;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();
          range.insertNode(insertEle);
          range.collapse(false); // 将选取合并
        }
      } else if (doc.selection && doc.selection.createRange) {
        doc.selection.createRange().text = insertEle;
      }
    }
    function insertHtml(html: string) {
      let selection = window.getSelection() as Selection
      let range = selection.getRangeAt(0)
      if (selection.rangeCount) {
        let el = document.createElement("div");
        el.innerHTML = html;
        let frag = document.createDocumentFragment(), node, lastNode;
        while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
        }
      }
    }

    // 选择表情
    const chooseEmotion = (e: Event) => {
      const emotion = (e.target as HTMLImageElement).cloneNode();
      const divEle = chatRecordService.chatContentTpl.value;
      if (divEle) {
        insertHtmlByRange(divEle, emotion);
      }
      chatRecordService.editorChange();
    };

    const chooseSpeech = (speech: string) => {
      contentChange(speech, speech);
    };

    const editorChange = () => {
      chatRecordService.editorChange();
    };
    function toggleSeeMe(){
      seeMe.value = !seeMe.value
      liveService.seeMe.value = seeMe.value
    }
    function onTextareaPaste(e: Event){
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignored
        let paste = (e.clipboardData || window.clipboardData).getData('text/plain')
        if(paste){
            insertHtml(xssFilterAllHtml.process(paste))
            editorChange()
        }
    }

    return {
      chatContentTpl: chatRecordService.chatContentTpl,
      hasContent: chatRecordService.hasContent,
      seeMe,
      sendChatContent,
      editorChange,
      chooseEmotion,
      chooseSpeech,
      toggleSeeMe,
      onTextareaPaste
    };
  },
});
</script>

<style lang="less" scoped>
.chat-wrapper {
  border-top: 1px solid #eee;
  .chat-edit-wrapper {
    position: relative;
    width: 100%;
    height: 75px;
    &::after {
      content: attr(placeholder);
      position: absolute;
      top: 10px;
      left: 10px;
      color: #a9a9a9;
    }
  }
  .chat-edit {
    width: 100%;
    height: 100%;
    padding-left: 5px;
    line-height: 20px;
    border: none;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    word-wrap: break-word;
    outline: none;
    & img {
      width: 20px;
    }
  }
}

.chat-action {
  display: flex;
  justify-content: flex-end;
  padding: 10px 18px 18px;
  box-sizing: border-box;
}

.chat-tools {
  display: flex;
  padding-top: 12px;
  justify-content: space-between;
  align-items: center;
  .seei{
    color: #A3ABB8;
    font-size: 14px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img{
      width: 18px;
      margin-right: 4px;
    }
    &[enable="1"]{
      color: #1db88e;
    }
  }
}
</style>
