<template>
   <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "App"
});
</script>

<style lang="less">
#app {
  font-family: PingFangSC-Regular, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-image: url("assets/images/bg.png");
  background-size: 100% 100%;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
