import { ref } from "vue";
import { getQueryParams } from "@/core/utils/url";
import { InjectionKey } from "vue";
import { Json } from "@/core/interface/base";

/**
 * live： 直播  vod：回放
 */
export type IMode = "live" | "vod";

export const LiveServiceKey: InjectionKey<LiveService> = Symbol();

export class LiveService {
  /** 直播模式 live直播 vod点播*/
  public mode = ref<IMode>("live");
  /** 展示互动资源id, 直播与点播值是不一致的 */
  public ownerid = ref<string>("");
  /** 展示互动校验码， 直播时需要 */
  public token = ref<string>("");
  /** 学员姓名 */
  public username = ref<string>("");
  /** 学员UID */
  public uid = ref<string>("");
  /**
   * 播放器类型，默认是h5, 链接上不用传
   */
  public player = "h5";
  /** 课包名称 */
  public coursePacketName = ref<string>("");
  /** 排课课时名称 */
  public lessonTimeName = ref<string>("");
  /** 排课课时id */
  public lessonTimeId = ref<string>("");
  /** 排课计划id */
  public lessonPlanId = ref<string>("");
  /** 头像地址 */
  public avatar = ref<string>("");
  /** 学生ID */
  public studentId = "";
  /** 赛道码 */
  public orgCode = 'cjsd';
  // 站点
  public site = 'xuelxuew.gensee.com'
  // 只看我
  public seeMe = ref<boolean>(false)

  constructor(data: Json) {
    this.initFromQueryParams(data);
    
    document.title = this.lessonTimeName.value || "开元直播平台";
  }
  // 从链接上获取参数以便初始化
  initFromQueryParams(data: Json) {
    const queryParams = getQueryParams();
    this.mode.value = data.type || queryParams.get("mode") as IMode;// mode: live|vod 直播/回放
    this.ownerid.value = data.liveNo as string;
    this.token.value = data.liveUid as string || '';
    this.username.value = data.nickName as string;
    this.uid.value = 1e4 + '' + data.thirdId as string; // 前面加10000即`10000${thirdId}`
    this.player = queryParams.get("player") || "h5";
    this.coursePacketName.value = data.courseName as string;
    this.lessonTimeName.value = data.courseTimeName as string;
    this.lessonTimeId.value = data.lessonTimeId as string;
    this.lessonPlanId.value = data.lessonPlanId as string;
    this.avatar.value = decodeURIComponent(data.photo as string || '');
    this.studentId = data.studentId as string;
    this.orgCode = data.orgCode as string;
    if(data.site){
      this.site = data.site as string;
    }
  }
}
