<template>
  <a-popover trigger="click" v-model:visible="isshow">
    <a
      href="javascript:void(0);"
      class="tool-icon"
      :class="{ 'tool-icon-seleted': isshow }"
    ></a>
    <template #content>
      <div class="emotion_list" @click="chooseEmotion">
        <img
          src="@/assets/images/emotion/emotion.smile.png"
          data-emotion-tag="emotion.smile"
        />
        <img
          src="@/assets/images/emotion/emotion.goodbye.png"
          data-emotion-tag="emotion.goodbye"
        />
        <img
          src="@/assets/images/emotion/emotion.laugh.png"
          data-emotion-tag="emotion.laugh"
        />
        <img
          src="@/assets/images/emotion/emotion.cry.png"
          data-emotion-tag="emotion.cry"
        />
        <img
          src="@/assets/images/emotion/emotion.angerly.png"
          data-emotion-tag="emotion.angerly"
        />
        <img
          src="@/assets/images/emotion/emotion.nod.png"
          data-emotion-tag="emotion.nod"
        />
        <img
          src="@/assets/images/emotion/emotion.lh.png"
          data-emotion-tag="emotion.lh"
        />
        <img
          src="@/assets/images/emotion/emotion.question.png"
          data-emotion-tag="emotion.question"
        />
        <img
          src="@/assets/images/emotion/emotion.bs.png"
          data-emotion-tag="emotion.bs"
        />
        <img
          src="@/assets/images/emotion/rose.up.png"
          data-emotion-tag="rose.up"
        />
        <img
          src="@/assets/images/emotion/rose.down.png"
          data-emotion-tag="rose.down"
        />
        <img
          src="@/assets/images/emotion/chat.gift.png"
          data-emotion-tag="chat.gift"
        />
        <img
          src="@/assets/images/emotion/feedback.quickly.png"
          data-emotion-tag="feedback.quickly"
        />
        <img
          src="@/assets/images/emotion/feedback.slowly.png"
          data-emotion-tag="feedback.slowly"
        />
        <img
          src="@/assets/images/emotion/feedback.agreed.png"
          data-emotion-tag="feedback.agreed"
        />
        <img
          src="@/assets/images/emotion/feedback.against.png"
          data-emotion-tag="feedback.against"
        />
        <img
          src="@/assets/images/emotion/feedback.applaud.png"
          data-emotion-tag="feedback.applaud"
        />
        <img
          src="@/assets/images/emotion/feedback.think.png"
          data-emotion-tag="feedback.think"
        />
      </div>
    </template>
  </a-popover>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "EmotionTool",
  setup(props, { emit }) {
    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    const chooseEmotion = (e: Event) => {
      emit("emotionChange", e);
      closePopover();
    };
    return {
      chooseEmotion,
      isshow,
    };
  },
});
</script>

<style scoped lang="less">
.tool-icon {
  display: block;
  width: 40px;
  height: 31px;
  background: url(../../../assets/images/chat-tools/icon-emtion-normal.png)
    no-repeat center;
  &:hover {
    background: url(../../../assets/images/chat-tools/icon-emtion-hover.png)
      no-repeat center;
  }
}

.tool-icon-seleted {
  background-image: url(../../../assets/images/chat-tools/icon-emtion-click.png);
}

.emotion_list {
  width: 168px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;

  img {
    display: block;
    margin: 2px;
  }
}
</style>
