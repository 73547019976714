
import { ref } from "vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { replaceParamVal } from "@/core/utils/url";

export default {
  name: "ChangePlayer",
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    const selectedPlayer = ref(liveService.player);
    const palyers = ref([
      { label: "低消耗H5", value: 'h5'},
      { label: "Flash播放器", value: 'flash'},
    ]);
    const choosePlayer = (value: string) => {
      selectedPlayer.value = value;
      closePopover();
      repalceUrlQueryParams(value);
    };

    const repalceUrlQueryParams = (val: string): void => {
      const url = window.location.href;
      const newUrl = replaceParamVal(url, "player", val);
      window.history.replaceState(null, document.title, newUrl);
      window.location.reload();
    }

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      selectedPlayer,
      palyers,
      choosePlayer,
      isshow,
    };
  },
};
