<template>
  <div class="change-player-wrapper">
    <a-popover
      color="#343434"
      overlayClassName="change-player-popover"
      trigger="click"
      v-model:visible="isshow"
    >
      <div class="player-name">{{ palyers[selectedPlayer === 'h5' ? 0 : 1].label }}</div>
      <template #content>
        <div class="player-list">
          <div
            v-for="(item, index) in palyers"
            :key="index"
            class="player-item"
            :class="{ selected: item.value === selectedPlayer }"
            @click="choosePlayer(item.value)"
          >
            {{ item.label }}
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { replaceParamVal } from "@/core/utils/url";

export default {
  name: "ChangePlayer",
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    const selectedPlayer = ref(liveService.player);
    const palyers = ref([
      { label: "低消耗H5", value: 'h5'},
      { label: "Flash播放器", value: 'flash'},
    ]);
    const choosePlayer = (value: string) => {
      selectedPlayer.value = value;
      closePopover();
      repalceUrlQueryParams(value);
    };

    const repalceUrlQueryParams = (val: string): void => {
      const url = window.location.href;
      const newUrl = replaceParamVal(url, "player", val);
      window.history.replaceState(null, document.title, newUrl);
      window.location.reload();
    }

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      selectedPlayer,
      palyers,
      choosePlayer,
      isshow,
    };
  },
};
</script>

<style scoped lang="less">
.change-player-wrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .player-name {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #00ab84;
    }
  }
}
</style>

<style lang="less">
.change-player-popover {
  .ant-popover-content .ant-popover-arrow {
    border-right-color: #343434 !important;
    border-bottom-color: #343434 !important;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .player-list {
    padding: 5px 0;
    color: #ffffff;
    font-size: 14px;

    .player-item {
      height: 30px;
      text-align: center;
      line-height: 30px;
      padding: 0 5px;
      cursor: pointer;

      &:hover {
        color: #00ab84;
        background-color: #434343;
      }
    }

    .selected {
      color: #00ab84;
    }
  }
}
</style>
