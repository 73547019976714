<template>
  <div class="video-play-rate-wrapper">
    <a-popover
      color="#343434"
      overlayClassName="video-play-rate-popover"
      trigger="click"
      v-model:visible="isshow"
    >
      <div class="rate">{{ selectedRate }}</div>
      <template #content>
        <div class="rate-list">
          <div
            v-for="(item, index) in rateRange"
            :key="index"
            class="rate-item"
            :class="{ selected: item === selectedRate }"
            @click="chooseRate(item)"
          >
            {{ item }}
          </div>
        </div>
      </template>
    </a-popover>
    <div class="rate-remark">倍速</div>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default {
  name: "VideoPlayRate",
  setup() {
    const selectedRate = ref("1.0X");
    const rateRange = ref<string[]>(["2.0X", "1.5X", "1.25X", "1.0X", '0.75X', '0.5X']);
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    // 选择倍数播放
    const chooseRate = (value: string) => {
      const numRate = Number(value.replace("X", ""));
      gsSdkService?.submitPlaybackRate(numRate);
      selectedRate.value = value;
      closePopover();
    };

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      selectedRate,
      rateRange,
      chooseRate,
      isshow,
    };
  },
};
</script>

<style scoped lang="less">
.video-play-rate-wrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .rate {
    font-size: 12px;
    color: #ffffff;
    width: 66px;
    height: 24px;
    border-radius: 30px;
    border: 1px solid #ffffff;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }

  .rate-remark {
    font-size: 14px;
    color: #ffffff;
  }
}
</style>

<style lang="less">
.video-play-rate-popover {
  .ant-popover-content .ant-popover-arrow {
    border-right-color: #343434 !important;
    border-bottom-color: #343434 !important;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .rate-list {
    padding: 5px 0;
    color: #ffffff;
    font-size: 14px;

    .rate-item {
      width: 72px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;

      &:hover {
        color: #00ab84;
        background-color: #434343;
      }
    }

    .selected {
      color: #00ab84;
    }
  }
}
</style>
