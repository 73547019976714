<template>
  <div class="fullscreen-wrapper" @click="toggleFullscreen">
    <img
      class="fullscreen-icon"
      :src=" require(fullscreen ? '@/assets/images/icon-exit-fullscreen.png' : '@/assets/images/icon-fullscreen.png')"
    />
    <!-- <div class="remark">
      {{ fullscreen ? "退出全屏" : "视频全屏" }}
    </div> -->
  </div>
</template>

<script lang="ts">
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useRequiredInject from '@/composable/useRequiredInject';

export default {
  name: "FullScreen",
  setup() {
    const videoToolsService = useRequiredInject(VideoToolsServiceKey);
    const toggleFullscreen = () => {
      const fullscreen = videoToolsService.fullscreenRef;
      fullscreen.value = !fullscreen.value;
    };
    return {
      toggleFullscreen,
      fullscreen: videoToolsService.fullscreenRef,
    };
  },
};
</script>

<style scoped lang="less">
.fullscreen-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  .fullscreen-icon {
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .remark {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #00ab84;
    }
  }
}
</style>
