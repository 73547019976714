
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "EmotionTool",
  setup(props, { emit }) {
    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    const chooseEmotion = (e: Event) => {
      emit("emotionChange", e);
      closePopover();
    };
    return {
      chooseEmotion,
      isshow,
    };
  },
});
