import gsUtil from "@/core/utils/gensee";
import { reactive, ref, InjectionKey } from "vue";

export interface IChatRecord {
  id: string;
  username: string;
  chatTime: string;
  chatContent: string;
  isMe: boolean;
  [k: string]: any;
}
export interface IChatEvent{
  more?: boolean;
  data: {
    list: IChatEvent[]
  }
}

export const ChatRecordServiceKey: InjectionKey<ChatRecordService> = Symbol();

export class ChatRecordService {
  public chatRecords = reactive<IChatRecord[]>([]);//聊天记录列表
  public listContentTpl = ref<HTMLDivElement>();
  public chatContentTpl = ref<HTMLDivElement>();
  public hasContent = ref<boolean>(false);

  public chatListScroll(): void {
    const listDivEle = this.listContentTpl.value;
    if (listDivEle) {
      listDivEle.scrollTop = listDivEle.scrollHeight;
    }
  }
  /**
   * 添加聊天记录
   * @param records 
   */
  public addChatRecord(...records: IChatRecord[]): void {
    this.chatRecords.push(...records);
  }

  public focusChatContent() {
    this.chatContentTpl.value?.focus();
  }

  public clearChatContent() {
    if (this.chatContentTpl.value) {
      this.chatContentTpl.value.innerHTML = "";
    }
  }

  public editorChange() {
    const divEle = this.chatContentTpl.value;
    if (divEle) {
      const content = gsUtil.trim(divEle.innerHTML).replace(/ /gi, " ").replace(/\s+/g, " ")
      let tempInnerText = divEle.innerText
      this.hasContent.value = !(gsUtil.isEmpty(content) || content === "<br>" || content === "<br/>" || ( !gsUtil.trim(tempInnerText.replace(/\n/gi, '')) && !/img/gi.test(content) ))
    }
  }
  // 更新聊天记录其中一项
  public updateChatRecordbyIndex(index: number, item: IChatRecord){
    if(index < 0) return
    if(this.chatRecords[index]){
      this.chatRecords[index] = item
    }
  }
  // 全量更新聊天记录
  public updateChatRecord(chatRecords: IChatRecord[] = []){
    this.chatRecords.length = 0
    this.chatRecords.push(...chatRecords)
  }
  // 排除重复聊天记录
  public updateChatRecordRemoveSame(chatRecords: IChatRecord[]=[]){
    if(!this.chatRecords.length){
      return this.chatRecords.push(...chatRecords)
    }
    if(!!chatRecords.length){
      chatRecords.map((chat) =>{
        // 存在同样一条聊天数据
        const isHitIndex = this.chatRecords.findIndex(exitChatItem => exitChatItem.id === chat.id && exitChatItem.submitTime === chat.submitTime && exitChatItem.chatContent === chat.chatContent)
        if(isHitIndex < 0){
          this.chatRecords.push(chat)
        }
      })
    }
  }
}
