import { IObject, KySafeAny } from "@/core/interface/base";
import { MGensee } from "@/core/interface/gensee.d";
import message from "ant-design-vue/lib/message";
import { InjectionKey, ref } from "vue";
import { CurrentVodTimeStorage } from "@/services/cache.service";

declare const GS: KySafeAny;

export const GsSdkServiceKey: InjectionKey<GsSdkService> = Symbol();

/**
 * 展示互动SDK服务
 * @address http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-31
 */
export class GsSdkService {
  private _channel: any;
  private _playNowTimerHandler: number;
  private _liveStatus = new Map<number, string>();
  private _vodStatus = new Map<number, string>();

  public netSettings = ref<MGensee.INetSettings[]>([]);
  public EventEnum = {
    onQA: 'onQA',// 收到问答消息
    onRollcall: 'onRollcall', // 收到点名通知
    onTextWebcast: 'onTextWebcast', // 文字直播
    onVote: 'onVote', // 收到调查问卷
    onVoteResult: 'onVoteResult', // 收到调查问卷
    onAnswerSheet: 'onAnswerSheet', // 收到答题卡
    onAnswerSheetAnswer: 'onAnswerSheetAnswer', //发布答题卡答案
    onThirdPartChat: 'onThirdPartChat', // 收到第三方聊天消息
    onVoteStop: 'onVoteStop', // 终止投票
    onSetting: 'onSetting', // 功能配置更改通知
    onUpdatePraiseTotalSuccess: 'onUpdatePraiseTotalSuccess', // 收到点赞剩余可点赞数量
    onPraiseMessage: 'onPraiseMessage', // 收到点赞消息
  };
  // 直播生命周期回调
  public LiveLifecycleEvent = {
    onStart: 'onStart', // 直播开始
    onPause: 'onPause', // 直播暂停
    onPlay: 'onPlay', // 直播恢复
    onStop: 'onStop', // 直播停止
    onStatus: 'onStatus', //  SDK状态通知
  }
  // 自定义视频播放状态
  public PlayStateEnum = {
    unStart: -1, // 未开始
    pending: 0, // 缓冲中
    play: 1, // 播放中
    pause: 2, // 暂停
    stop: 3 // 停止
  }
  /**
   * onStatus SDK状态通知
   * @api http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-42
   */
  public SDKStatusEnum = {
    licenseInvalid: 1, // License不足；
    liveUnstart: 2, // 直播未开始
    pending: 3, // 缓冲状态
    joinFailWithServiceRefuse: 4, // 服务器拒绝，加入失败；
    connecting: 5, // 连接服务器中；
    joinFailWithUserLoginByClient: 6, // 加入失败，该用户已用客户端形式登录；
    joinFailWithRoomLocked: 7, // 直播上锁，拒绝加入；
    userWithBan: 8, // 用户已被封，加入失败
    pendingWithFirst: 9 // 视频第一次缓冲播放开始（H5播放器支持）；
  }
  // 码流
  public streamList = [
    {level: 0, label: '标清'},
    {level: 1, label: '原始'}
  ]

  constructor() {
    this._playNowTimerHandler = 0;
    this.initLiveStatus();
    this.initVodStatus();
    this.createChannel();
  }

  public createChannel(): void {
    console.log('[createChannel]');
    // @ts-ignore
    if(!window.GS) return
    if (!this._channel) {
      // @ts-ignore
      this._channel = window.GS.createChannel();
    }
  }

  public getChannel(): any {
    return this._channel;
  }

  public on(eventName: string, cb: (event: MGensee.IGsEvent) => void): void {
    if(!this._channel) return
    this._channel.bind(eventName, cb);
  }

  public off(eventName: string): void {
    this._channel.unbind(eventName);
  }

  public send(type: string, data: IObject, cb?: (data: any) => void): void {
    console.log('[send] type: ', type, ' data:', data);
    this._channel.send(type, data, cb);
  }

  public getPlayNow(): void {
    this.clearPlayNowTimerHandler();
    this._playNowTimerHandler = setInterval(() => {
      this.playheadTime();
    }, 400);
  }

  public clearPlayNowTimerHandler(): void {
    clearInterval(this._playNowTimerHandler);
  }

  //#region Action

  public submitChatHistory(): void {
    this.send("submitChatHistory", {});
  }

  public playheadTime(): void {
    this.send("playheadTime", {});
  }

  public submitVolume(value: number): void {
    this.send("submitVolume", { value });
  }

  /**
   * 跳转到指定时间点
   * @param timestamp 指定时间点，单位毫秒
   */
  public seek(timestamp: number): void {
    this.send("seek", { timestamp });
  }

  /**
   * 通过接口控制音视频倍速播放
   * @param playbackRate 倍数可配置范围：0.5-4，微调精度：0.25
   */
  public submitPlaybackRate(playbackRate: number): void {
    this.send("submitPlaybackRate", { playbackRate });
  }

  /**
   * 开始播放/暂停后恢复播放
   * @attention 该api只在不采用默认控制条的前提下生效
   */
  public play(): void {
    this.send("play", {});
  }

  /**
   * 暂停播放
   * @attention 该api只在不采用默认控制条的前提下生效
   */
  public pause(): void {
    this.send("pause", {});
  }

  public submitChat(params: IObject, cb?: () => void): void {
    this.send("submitChat", params, cb);
  }

  /**
   * 提交优选网络选择信息
   * @param network 网络名称
   */
  public submitNetChoice(network: string): void {
    this.send("submitNetChoice", { label: network });
  }

  /**
   * 请求优选网络信息
   */
  public requireNetSettings(): void {
    this.send("requireNetSettings", {});
  }

  //#endregion

  public vodEvent(): void {
    console.log("vod nothing do yet");
  }
  // 开启或关闭聊天数据同步功能。
  public setupChatSync(open: boolean): void{
    this.send('setupChatSync', {open})
  }
  public submitChatSegment(){
    this.send('submitChatSegment', {})
  }
  public liveEvent(): void {
    // // 直播开启通知
    // this.on("onStart", (event) => {
    //   message.info("直播开始啦！");
    // });

    // // 直播暂停通知
    // this.on("onPause", (event) => {
    //   message.info("直播暂停啦！");
    // });

    // // 直播恢复通知
    // this.on("onPlay", (event) => {
    //   message.info("直播恢复啦！");
    // });

    // // 直播停止通知
    // this.on("onStop", (event) => {
    //   message.info("直播停止啦！");
    // });

    // 被踢出
    this.on("onKickOut", function (event) {
      const reason = Number(event.data.reason);
      if (reason === 0) {
        message.warning("被会议组织者踢出");
      } else {
        message.warning("被踢出直播");
      }
    });
    
    // 获取优选网络信息
    this.on("onNetSettings", (event) => {
      const { list } = event.data;
      const formatList = (list as any[]).map(item => ({
        ...item,
        selected: item.selected === "true"
      }));
      this.netSettings.value = formatList;
    });
    // 文档没注明的隐藏接口
    this.on('onListenerEvent', event =>{
      // 网络中断
      // {code: "4048", type: "hlsError", ...}
      if(+event.data.code === 4048){
        // message.warn('您的网络已断开，请检查网络情况')
      }
    })
  }

  public registerEvent(mode: string): void {
    if (mode === "vod") {
      this.vodEvent();
    }

    if (mode === "live") {
      this.liveEvent();
    }
  }

  public getStatus(mode: any, type: number): string {
    let desc = "";
    if (mode === "live") {
      desc = this._liveStatus.get(type) || "";
    } else {
      if (type === 2 || type === 9) {
        return "";
      }
      desc = this._vodStatus.get(type) || "";
    }
    return desc;
  }

  /**
   * 回滚回放播放历史
   */
  public rollbackSeekHistory(ownerid: string) {
    const time = CurrentVodTimeStorage.get(ownerid);
    if (time !== 0) {
      this.seek(time * 1000);
    }
  }

  private initLiveStatus(): void {
    this._liveStatus.set(1, "License不足");
    this._liveStatus.set(2, "直播未开始");
    this._liveStatus.set(3, "缓冲状态");
    this._liveStatus.set(4, "服务器拒绝，加入失败");
    this._liveStatus.set(5, "连接服务器中");
    this._liveStatus.set(6, "加入失败，该用户已用客户端形式登录");
    this._liveStatus.set(7, "直播上锁，拒绝加入");
    this._liveStatus.set(8, "该用户已被封，加入失败");
    this._liveStatus.set(9, "视频第一次缓冲播放开始（H5播放器支持）");
  }

  private initVodStatus(): void {
    this._vodStatus.set(1, "License不足");
    this._vodStatus.set(2, "点播未开始，等待点击开始按钮");
    this._vodStatus.set(3, "缓冲状态");
    this._vodStatus.set(4, "不能在ipad中播放");
    this._vodStatus.set(5, "正在执行seek命令");
    this._vodStatus.set(6, "表示有人登陆");
    this._vodStatus.set(7, "人数已经满了");
    this._vodStatus.set(8, "数据还没有准备好");
    this._vodStatus.set(9, "视频第一次缓冲播放开始");
  }
  /**
   * 选择码流
   * @param {0 | 1} level 码流。0-标清 1-原始
   * @see 在多码流间切换。是否支持多码流参见onSetting接口说明 http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-19
   */
  public submitStream(level: MGensee.TStream['level']): void{
    this.send('submitStream', {
      level: level
    })
  }
  /**
   * 发起举手
   * @param {booean} handup 
   * @see http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-4
   */
  submitHandup(handup: boolean): Promise<any>{
    return new Promise(resolve =>{
      this.send('submitHandup', {handup}, e => resolve(e))
    })
  }
  /**
   * 提交点赞
   * @param {number} remain 当前用户剩余点赞数量
   * @see http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-101 
   */
  submitPraise(remain: number): Promise<any>{
    return new Promise(resolve =>{
      this.send('submitPraise', {remain}, e => resolve(e))
    })
  }
  /**
   * 提交静音信息
   * @param {Boolean} mute 是否设置为静音。
   * @returns {Promise} 
   * @see http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-85
   */
  submitMute(mute: boolean): Promise<any>{
    return new Promise(resolve =>{
      this.send('submitMute', {mute}, e => resolve(e))
    })
  }
}
