import testEnv from "./environment.test";
import preEnv from "./environment.pre";
import prodEnv from "./environment.prod";
// 获取packjson中构建的环境VUE_APP_ENV_CONGIG
const envConfig = process.env.VUE_APP_ENV_CONGIG;

let _environment: any;
if (envConfig === "test") {
  _environment = testEnv;
}else if (envConfig === "pre") {
  _environment = preEnv;
} else {
  _environment = prodEnv;
}
// 导出当前所处环境变量
export const environment = _environment;
