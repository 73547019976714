
export interface IPlayBackVideo {
  timeName: string;
  timeId: string;
  startTime: number;
  endTime: number;
  attendance: string;
  url: string;
}

import { defineComponent, ref } from "vue";
import { getRecordList } from "@/api/live.api";
import { format, isSameDay } from "date-fns";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default defineComponent({
  name: "PlaybackVideo",
  setup() {
    const liveServie = useRequiredInject(LiveServiceKey);
    const vods = ref<IPlayBackVideo[]>([]);
    const loading = ref(true);
    // 获取回放记录列表
    getRecordList(liveServie.lessonPlanId.value, liveServie.studentId).then(res => {
      loading.value = false;
      if (res.status === 200) {
        if(res.data && res.data.length){
          // 排除没回放的数据
          res.data = res.data.filter((item: any) => item.url)
        }
        vods.value = res.data || [];
      }
    }).catch(err => {
      loading.value = false;
    })
    function goToVideoPage(video: IPlayBackVideo) {
      location.href = video.url
    }

    function isEmpty(): boolean {
      return vods.value.length === 0;
    }

    function fomatDate(video: IPlayBackVideo): string {
      const { startTime, endTime } = video;
      if (!startTime || !endTime) {
        return '--';
      }
      if (isSameDay(startTime, endTime)) {
        const dayStr = format(startTime, 'M月d日');
        const startTimeStr = format(startTime, 'HH:mm');
        const endTimeStr = format(endTime, 'HH:mm');
        return `${dayStr} ${startTimeStr}~${endTimeStr}`;
      } else {
        const startTimeStr = format(startTime, 'M月d日 HH:mm');
        const endTimeStr = format(endTime, 'M月d日 HH:mm');
        return `${startTimeStr}~${endTimeStr}`;
      }
    }
    return {
      vods,
      loading,
      goToVideoPage,
      isEmpty,
      fomatDate,
      timeId: liveServie.lessonTimeId.value
    }
  }
});
