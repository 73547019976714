import { createApp } from "vue";
import App from "./App.vue";

import { Button, Avatar, Popover, Slider, Tabs, Empty, Spin } from "ant-design-vue";

import "@/styles/index.less";
import { GsSdkService, GsSdkServiceKey } from "@/services/gs-sdk.service";
import { VideoToolsService, VideoToolsServiceKey } from "@/services/video-tools.service";
import { LiveService, LiveServiceKey } from "@/services/live.service";
import { router } from '@/router'

// app.provide(LiveServiceKey, new LiveService());
// app.provide(VideoToolsServiceKey, new VideoToolsService());
// app.provide(GsSdkServiceKey, new GsSdkService());

const app = createApp(App);
// @ts-ignore
window.app = app
app.use(Button);
app.use(Avatar);
app.use(Popover);
app.use(Slider);
app.use(Tabs);
app.use(Empty);
app.use(Spin);
app.use(router)
app.mount("#app");

