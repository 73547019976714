const PRE_KEY = 'KY-LIVE';

export type IStorageType = "local" | "session";

export const createKey = (key: string): string => {
  return PRE_KEY + '_' + key;
}

export const getStorage = (type: IStorageType): Storage => {
  return type === 'session' ? window.sessionStorage : window.localStorage;
}

export const getItem = (type: IStorageType, key: string, skipPrex?: boolean) => {
  let _key = key;
  if (!skipPrex) {
    _key = createKey(key);
  }
  const storage = getStorage(type);
  const value = storage.getItem(_key);
  if (value) { 
    try {
      const _value = JSON.parse(value);
      return _value;
    } catch (error) {
      return value;
    }
  } else {
    return null;
  }
}

export const setItem = (type: IStorageType, key: string, value: any, skipPrex?: boolean): void => {
  let _key = key;
  if (!skipPrex) {
    _key = createKey(key);
  }
  const storage = getStorage(type);
  const valType = typeof value;
  let _value = value;
  if (valType === "number") {
    _value = value.toString();
  } else if (valType === "string"){
    _value = value;
  } else {
    _value = JSON.stringify(value);
  }
  return storage.setItem(_key, _value);
}

export const clearItem = (type: IStorageType, key: string, skipPrex?: boolean) => {
  let _key = key;
  if (!skipPrex) {
    _key = createKey(key);
  }
  const storage = getStorage(type);
  storage.removeItem(_key);
}

export const clearAll = (type: IStorageType): void => {
  const storage = getStorage(type);
  storage.clear();
}

export const storageKeys = (type: IStorageType): string[] => {
  const storage = getStorage(type);
  return Object.keys(storage);
}