<template>
  <div class="change-stream-wrapper">
    <a-popover
      color="#343434"
      overlayClassName="change-stream-popover"
      trigger="click"
      v-model:visible="isshow"
    >
      <div class="name">{{selectedStream}}</div>
      <template #content>
        <div class="network-list">
          <div
            v-for="(item, index) in streamList"
            :key="index"
            class="network-item"
            :class="{selected: item.label === selectedStream || item.selected}"
            @click="chooseStream(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { MGensee } from "@/core/interface/gensee.d";
import useRequiredInject from "@/composable/useRequiredInject";

export default {
  name: "LiveStream",
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const selectedStream = ref(gsSdkService.streamList[1].label);
    const chooseStream = (item: MGensee.TStream) => {
      selectedStream.value = item.label;
      gsSdkService.submitStream(item.level);
      closePopover();
    };

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      streamList: gsSdkService.streamList,
      selectedStream,
      chooseStream,
      isshow,
    };
  },
};
</script>

<style scoped lang="less">
.change-stream-wrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .name {
    font-size: 14px;
    color:#ffffff;
    cursor: pointer;
    &:hover {
      color: #00ab84;
    }
  }
}
</style>

<style lang="less">
.change-stream-popover {
  .ant-popover-content .ant-popover-arrow {
    border-right-color: #343434 !important;
    border-bottom-color: #343434 !important;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .network-list {
    padding: 5px 0;
    color: #ffffff;
    font-size: 14px;

    .network-item {
      width: 80px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      padding: 0 5px;
      cursor: pointer;

      &:hover {
        color: #00ab84;
        background-color: #434343;
      }
    }

    .selected {
      color: #00ab84;
    }
  }
}
</style>
