const gsUtil = {
  trim: function (str: string): string {
    if (typeof str !== "string") {
      return str;
    }
    if (typeof str.trim === "function") {
      return str.trim();
    } else {
      return str.replace(
        /^(\u3000|\s|\t|\u00A0)*|(\u3000|\s|\t|\u00A0)*$/g,
        ""
      );
    }
  },
  isEmpty: function (obj: any): boolean {
    if (obj === undefined) {
      return true;
    } else if (obj === null) {
      return true;
    } else if (typeof obj === "string") {
      if (this.trim(obj) === "") {
        return true;
      }
    }
    return false;
  },
  isNotEmpty: function (obj: any): boolean {
    return !this.isEmpty(obj);
  },
  breachHTML: function (str: string): string {
    if (typeof str !== "string" || this.isEmpty(str)) return str;
    return str.replace(/</g, "&lt;");
  },
  escapeHTML: function (str: string): string {
    if (typeof str !== "string" || this.isEmpty(str)) return str;
    return str.replace(/&/g, "&amp;").replace(/</g, "&lt;");
  },
  checkTime: function (num: any): string {
    let r = "";
    const n = Number(num);
    r = n.toString();
    if (n < 10) r = "0" + n;
    return r;
  },
  timeDuration: function (second: number): string {
    const defaultFormat = "00:00";
    if (!second || isNaN(second)) return defaultFormat;
    let time = "";
    const hour = (second / 3600) | 0;
    if (hour != 0) {
      time += this.checkTime(hour) + ":";
    }
    const min = ((second % 3600) / 60) | 0;
    time += this.checkTime(min) + ":";
    const sec = (second - hour * 3600 - min * 60) | 0;
    time += this.checkTime(sec);
    return time;
  },
  calcPercent: function (value: number, total: number): string | number {
    if (isNaN(value) || Number(value) == 0) return "0";
    if (isNaN(total) || Number(total) == 0) return "0";
    return Math.round((Number(value) * 100) / Number(total));
  },
  formatTime: function (time: number): string {
    const date = new Date();
    date.setTime(time);
    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();
    return (
      this.checkTime(h) + ":" + this.checkTime(m) + ":" + this.checkTime(s)
    );
  },
  //占位符替换
  replaceholder: function (str: string, values: string): string {
    return str.replace(/\{(\d+)\}/g, function (m, i) {
      return values[i];
    });
  },
  // 把unix转换为HH:mm:ss时间格式
  formateUnix(unix: number){
    const date = new Date(unix * 1e3)
    const enhance = (n: number) => n > 9 ? String(n) : `0${n}`
    return enhance(date.getHours()) + ':' + enhance(date.getMinutes()) + ':' + enhance(date.getSeconds())
  }
};

//截取标签名称
// function changeUrl(match: string): string{
//   const idx1 = match.indexOf("src=");
//   const idx2 = match.indexOf("emotion");
//   return match.substring(0, idx1 + 5) + match.substring(idx2);
// }

function getEmotionTag(str: string): string {
  const arr = str.match(/data-emotion-tag="[^"]*"/gi);
  if (!arr) {
    return "";
  }
  const target = arr[0];
  return target.replace("data-emotion-tag=", "").replace(/"|'/g, "");
}

//标签做ubb代码替换
export function emotionNormalize(
  richText: string,
  isText: boolean,
  local?: string
): string {
  richText = richText.replace(/<img.*?>/gi, function (match): string {
    let note = "";
    // if (!isText && (typeof local == "undefined" || !local))
    //   match = changeUrl(match);
    let code = "";
    const tag = getEmotionTag(match);
    switch (tag) {
      case "feedback.quickly":
        code = "$^tk^";
        if (isText) note = "【太快了】";
        break;
      case "feedback.slowly":
        code = "$^tm^";
        if (isText) note = "【太慢了】";
        break;
      case "feedback.agreed":
        code = "$^zt^";
        if (isText) note = "【赞同】";
        break;
      case "feedback.against":
        code = "$^fd^";
        if (isText) note = "【反对】";
        break;
      case "feedback.applaud":
        code = "$^gz^";
        if (isText) note = "【鼓掌】";
        break;
      case "feedback.think":
        code = "$^sk^";
        if (isText) note = "【值得思考】";
        break;
      case "chat.gift":
        code = "$^lw^";
        if (isText) note = "【礼物】";
        break;
      case "emotion.angerly":
        code = "$^fn^";
        if (isText) note = "【愤怒】";
        break;
      case "emotion.bs":
        code = "$^qm^";
        if (isText) note = "【鄙视】";
        break;
      case "emotion.cry":
        code = "$^sx^";
        if (isText) note = "【伤心】";
        break;
      case "emotion.goodbye":
        code = "$^zj^";
        if (isText) note = "【再见】";
        break;
      case "emotion.laugh":
        code = "$^gx^";
        if (isText) note = "【高兴】";
        break;
      case "emotion.lh":
        code = "$^lh^";
        if (isText) note = "【流汗】";
        break;
      case "emotion.nod":
        code = "$^wl^";
        if (isText) note = "【无聊】";
        break;
      case "emotion.question":
        code = "$^yw^";
        if (isText) note = "【疑问】";
        break;
      case "emotion.smile":
        code = "$^nh^";
        if (isText) note = "【你好】";
        break;
      case "rose.down":
        code = "$^dx^";
        if (isText) note = "【凋谢】";
        break;
      case "rose.up":
        code = "$^xh^";
        if (isText) note = "【鲜花】";
        break;

      default:
    }
    if (typeof local == "undefined" || !local) match = code;
    if (isText) {
      return note;
    } else {
      return match + note;
    }
  });
  return richText;
}

//表情替换成本地资源
export function emotion2Local(richText: string): string {
  richText = richText.replace(
    /<img.*?emotion(\\|\/)(.+?).(gif|png)".*?>/gi,
    function (match) {
      return changeUrl2Local(match);
    }
  );
  return richText
    .replace(/color: rgb(0, 0, 0);/gi, "")
    .replace(/COLOR: #000000;/gi, "")
    .replace(/color: rgb(92, 92, 92);/gi, "")
    .replace(/COLOR: #5c5c5c;/gi, "")
    .replace(/<font color=#[^>]+>/gi, "")
    .replace(/<\/font>/gi, "");
}
function generateImgHtml(src: string): string {
  return `<img src=${src} />`;
}
/** 文字表情替换成本地资源 */
export function emotionText2Local(richText: string): string {
  richText = richText.replace(/【[\u4e00-\u9fa5]+】/gi, function (match) {
    const publicPath = process.env.BASE_URL;
    let imgStr = "";
    switch (match) {
      case "【太快了】":
        imgStr = `${publicPath}static/images/emotion/feedback.quickly.png`;
        break;
      case "【太慢了】":
        imgStr = `${publicPath}static/images/emotion/feedback.slowly.png`;
        break;
      case "【赞同】":
        imgStr = `${publicPath}static/images/emotion/feedback.agreed.png`;
        break;
      case "【反对】":
        imgStr = `${publicPath}static/images/emotion/feedback.against.png`;
        break;
      case "【鼓掌】":
        imgStr = `${publicPath}static/images/emotion/feedback.applaud.png`;
        break;
      case "【值得思考】":
        imgStr = `${publicPath}static/images/emotion/feedback.think.png`;
        break;
      case "【礼物】":
        imgStr = `${publicPath}static/images/emotion/chat.gift.png`;
        break;
      case "【愤怒】":
        imgStr = `${publicPath}static/images/emotion/emotion.angerly.png`;
        break;
      case "【鄙视】":
        imgStr = `${publicPath}static/images/emotion/emotion.bs.png`;
        break;
      case "【伤心】":
        imgStr = `${publicPath}static/images/emotion/emotion.cry.png`;
        break;
      case "【再见】":
        imgStr = `${publicPath}static/images/emotion/emotion.goodbye.png`;
        break;
      case "【高兴】":
        imgStr = `${publicPath}static/images/emotion/emotion.laugh.png`;
        break;
      case "【流汗】":
        imgStr = `${publicPath}static/images/emotion/emotion.lh.png`;
        break;
      case "【无聊】":
        imgStr = `${publicPath}static/images/emotion/eemotion.nod.png`;
        break;
      case "【疑问】":
        imgStr = `${publicPath}static/images/emotion/emotion.question.png`;
        break;
      case "【你好】":
        imgStr = `${publicPath}static/images/emotion/emotion.smile.png`;
        break;
      case "【凋谢】":
        imgStr = `${publicPath}static/images/emotion/rose.down.png`;
        break;
      case "【鲜花】":
        imgStr = `${publicPath}static/images/emotion/rose.up.png`;
        break;

      default:
    }
    if (imgStr) {
      return generateImgHtml(imgStr);
    } else {
      return match;
    }
  });
  return richText;
}

//替换链接
export function changeUrl2Local(match: string): string {
  const idx1 = match.indexOf("src=");
  const idx2 = match.indexOf("emotion");
  return (
    match.substring(0, idx1 + 5) +
    "images/" +
    match.substring(idx2).replace('.gif"', '.png"')
  );
}

/*自动滚动到底部*/
// (function ($) {
//   $.fn.scrollTo = function (options) {
//     let defaults = {
//       to: "bottom", //"top":滚至顶部,"bottom":滚至底部
//       fn: function () {},
//     };
//     let opts = $.extend({}, defaults, options);
//     return this.each(function () {
//       let self = $(this);
//       //console.log(self);
//       let parent = self.parent();
//       //console.log(parent);
//       let height = self.outerHeight();

//       let scrollTop = parent.scrollTop();
//       let parentHeight = parent.height();
//       let scrollHeight = self.outerHeight();
//       //console.log(height+" "+scrollTop+" "+parentHeight+" "+scrollHeight+" "+self.find('li').last().outerHeight());
//       //console.log(self.attr('id'));
//       //console.log(scrollTop+parentHeight,scrollHeight-self.find('li').last().outerHeight()-self.find('li').eq(self.find('li').size()-2).outerHeight());

//       if (scrollTop + parentHeight <= scrollHeight) {
//         opts.fn();
//         switch (opts.to) {
//           case "bottom":
//           case "tobottom":
//             //console.log('ddd');
//             parent.scrollTop(height);
//             break;
//           case "top":
//             parent.scrollTop(0);
//             break;
//           default:
//             console.log(opts.to);
//         }
//       } else {
//         opts.fn();
//       }
//     });
//   };
// })(jQuery);

export default gsUtil;
