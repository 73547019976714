<template>
  <div class="changeview-wrapper" @click="changeView">
    <img class="changeview-icon" src="@/assets/images/change-view.png" />
    <!-- <div class="remark">切换视角</div> -->
  </div>
</template>

<script lang="ts">
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useRequiredInject from '@/composable/useRequiredInject';

export default {
  name: "ChangeView",
  setup() {
    const videoToolsService = useRequiredInject(VideoToolsServiceKey);
    const changeView = () => {
      videoToolsService.changeViewRef.value =
        videoToolsService.changeViewRef.value === "doc" ? "video" : "doc";
    };
    return {
      changeView,
    };
  },
};
</script>

<style scoped lang="less">
.changeview-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  .changeview-icon {
    display: block;
    width: 18px;
    height: 18px;
  }

  .remark {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #00ab84;
    }
  }
}
</style>
