
import { defineComponent } from "vue";
import ChatRecord from "@/components/ChatRecord/ChatRecord.vue";
import Tasks from "@/components/Tasks.vue";
import PlaybackVideo from "@/components/PlaybackVideo.vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default defineComponent({
  name: "FunctionArea",
  components: {
    ChatRecord,
    Tasks,
    PlaybackVideo,
  },
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    return {
      mode: liveService.mode,
    };
  },
});
