/**
 * >=min && <=max
 * @param min 
 * @param max 
 */
 function getRandom(min: number, max: number) {
  return min + Math.floor(Math.random() * (max - min + 1))
}
export default class ThumbsUpAni {
  imgsList: any[] = [];
  context;
  width = 0;
  height = 0;
  scanning = false;
  renderList: any[]=[];
  scaleTime = 0.1;// 百分比
  constructor(canvasId: string) {
      this.loadImages();
      const canvas = document.getElementById(canvasId) as HTMLCanvasElement
      this.context = canvas.getContext('2d') as CanvasRenderingContext2D;
      this.width = canvas.width;
      this.height = canvas.height;
  }
  loadImages() {
    const publicPath = process.env.BASE_URL;
      const images = [
          `static/images/like/74a73178e31bd021.png`,
          `static/images/like/608efd890fd61486.png`,
          `static/images/like/5165fdf5621d5bbf.png`,
          `static/images/like/98803f8ad07147b9.png`,
          `static/images/like/bfa59f27e654e29c.png`
      ];
      const promiseAll: Promise<any>[] = [];
      images.forEach((src) => {
          const p = new Promise(function (resolve) {
              const img = new Image;
              img.onerror = img.onload = resolve.bind(null, img);
              img.src = publicPath + src;
          });
          promiseAll.push(p);
      });
      Promise.all(promiseAll).then((imgsList) => {
          this.imgsList = imgsList.filter((d) => {
              if (d && d.width > 0) return true;
              return false;
          });
          if (this.imgsList.length == 0) {
              console.log('error', 'imgsList load all error');
              return;
          }
      })
  }
  createRender() {
      if (this.imgsList.length == 0) return null;
      const basicScale = [0.6, 0.9, 1.2][getRandom(0, 2)];

      const getScale = (diffTime: number) => {
          if (diffTime < this.scaleTime) {
              return +((diffTime/ this.scaleTime).toFixed(2)) * basicScale;
          } else {
              return basicScale;
          }
      };
      const context = this.context as CanvasRenderingContext2D;
      // 随机读取一个图片来渲染
      const image = this.imgsList[getRandom(0, this.imgsList.length - 1)]
      const offset = 20;
      const basicX = this.width / 2 + getRandom(-offset, offset);
      const angle = getRandom(2, 10);
      let ratio = getRandom(10,30)*((getRandom(0, 1) ? 1 : -1));
      const getTranslateX = (diffTime: number) => {
          if (diffTime < this.scaleTime) {// 放大期间，不进行摇摆位移
              return basicX;
          } else {
              return basicX + ratio*Math.sin(angle*(diffTime - this.scaleTime));
          }
      };

      const getTranslateY = (diffTime: number) => {
          return image.height / 2 + (this.height - image.height / 2) * (1-diffTime);
      };

      const fadeOutStage = getRandom(14, 18) / 100;
      const getAlpha = (diffTime: number) => {
          let left = 1 - +diffTime;
          if (left > fadeOutStage) {
              return 1;
          } else {
              return 1 - +((fadeOutStage - left) / fadeOutStage).toFixed(2);
          }
      };

      return (diffTime: number) => {
          // 差值满了，即结束了 0 ---》 1
          if(diffTime>=1) return true;
          context.save();
          const scale = getScale(diffTime);
          // const rotate = getRotate();
          const translateX = getTranslateX(diffTime);
          const translateY = getTranslateY(diffTime);
          context.translate(translateX, translateY);
          context.scale(scale, scale);
          // context.rotate(rotate * Math.PI / 180);
          context.globalAlpha = getAlpha(diffTime);
          context.drawImage(
              image,
              -image.width / 2,
              -image.height / 2,
              image.width,
              image.height
          );
          context.restore();
      };
  }
  scan() {
      this.context.clearRect(0, 0, this.width, this.height);
      this.context.fillStyle = "rgba(225,225,225,0)";
      this.context.fillRect(0,0,200,400);
      let index = 0;
      let length = this.renderList.length;
      if (length > 0) {
          requestFrame(this.scan.bind(this));
          this.scanning = true;
      } else {
          this.scanning = false;
      }
      while (index < length) {
          const child: {[k: string]: any} = this.renderList[index];
          if (!child || !child.render || child.render.call(null, (Date.now() - child.timestamp) / child.duration)) {
              // 结束了，删除该动画
              this.renderList.splice(index, 1);
              length--;
          } else {
              // continue
              index++;
          }
      }
  }
  start() {
      const render = this.createRender();
      const duration = getRandom(1500, 3000);
      this.renderList.push({
          render,
          duration,
          timestamp: Date.now(),
      });
      if (!this.scanning) {
          this.scanning = true;
          requestFrame(this.scan.bind(this));
      }
      return this;
  }
}

function requestFrame(cb: any) {
  // @ts-ignore
  return ( window.requestAnimationFrame || window.webkitRequestAnimationFrame || function(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  )(cb);
}