
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { MGensee } from "@/core/interface/gensee.d";
import useRequiredInject from "@/composable/useRequiredInject";

export default {
  name: "LiveStream",
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const selectedStream = ref(gsSdkService.streamList[1].label);
    const chooseStream = (item: MGensee.TStream) => {
      selectedStream.value = item.label;
      gsSdkService.submitStream(item.level);
      closePopover();
    };

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      streamList: gsSdkService.streamList,
      selectedStream,
      chooseStream,
      isshow,
    };
  },
};
