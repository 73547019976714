<template>
  <div class="function-area-wrapper">
    <a-tabs class="ant-tabs-c">
      <a-tab-pane key="1" tab="聊天记录">
        <ChatRecord />
      </a-tab-pane>
      <a-tab-pane key="2" tab="任务">
        <Tasks />
      </a-tab-pane>
      <a-tab-pane key="3" tab="回放" v-if="mode === 'vod'">
        <PlaybackVideo />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ChatRecord from "@/components/ChatRecord/ChatRecord.vue";
import Tasks from "@/components/Tasks.vue";
import PlaybackVideo from "@/components/PlaybackVideo.vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default defineComponent({
  name: "FunctionArea",
  components: {
    ChatRecord,
    Tasks,
    PlaybackVideo,
  },
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    return {
      mode: liveService.mode,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.function-area-wrapper {
  width: 100%;
  height: 100%;
  :deep {
    .ant-tabs-nav-scroll {
      background-color: #ededed;
      color: #a3abb8;
      .ant-tabs-nav {
        width: 100%;
        & > div {
          display: flex;
          width: 100%;
        }

        .ant-tabs-tab {
          margin: 0;
          flex: 1;
          text-align: center;
        }
      }
    }
    .ant-tabs-tab-active {
      background-color: #fff;
    }
  }
}

.ant-tabs {
  height: 100%;
  :deep .ant-tabs-top-content,
  .ant-tabs-bottom-content {
    height: calc(100% - 46px);
  }

  :deep .ant-tabs-bar {
    margin: 0;
  }
}
</style>
