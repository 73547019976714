<template>
  <div class="like">
    <canvas class="like-canvas" id="canvas" width="200" height="400"/>
     <img
      class="icon"
      src="@/assets/images/heart@2x.png"
      @click="doLike"
    />
  </div>
</template>

<script lang="ts">
import { nextTick, ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { MGensee } from "@/core/interface/gensee.d";
import useRequiredInject from "@/composable/useRequiredInject";
import ThumbsUpAni from '@/core/utils/ThumbsUpAni'
export default {
  name: "Like",
  components: {
    
  },
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    let thumbsUpAni: ThumbsUpAni
    gsSdkService.on(gsSdkService.EventEnum.onUpdatePraiseTotalSuccess, (e) =>{
      
    })
    nextTick(() =>{
      thumbsUpAni = new ThumbsUpAni('canvas')
    })
    function doLike(){
      thumbsUpAni && thumbsUpAni.start()
      gsSdkService.submitPraise(1)
    }

    return {
      doLike
    };
  },
};
</script>

<style scoped lang="less">
.like{
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  user-select: none;
  .icon{
    width: 20px;
  }
  .like-canvas{
    position: absolute;
    left: -90px;
    bottom: 44px;
    pointer-events: none;
  }
}
</style>

<style lang="less">

</style>
