
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default {
  name: "VideoPlayRate",
  setup() {
    const selectedRate = ref("1.0X");
    const rateRange = ref<string[]>(["2.0X", "1.5X", "1.25X", "1.0X", '0.75X', '0.5X']);
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    // 选择倍数播放
    const chooseRate = (value: string) => {
      const numRate = Number(value.replace("X", ""));
      gsSdkService?.submitPlaybackRate(numRate);
      selectedRate.value = value;
      closePopover();
    };

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      selectedRate,
      rateRange,
      chooseRate,
      isshow,
    };
  },
};
