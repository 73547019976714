
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { getItem, setItem } from '@/core/utils/storage';

export default {
  name: "Volume",
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const key = 'volume'
    const value = getItem('local', key)
    const DEF_VOLUME = 50
    const defaultValue = +( value !== null ? value : DEF_VOLUME)
    const sliderValue = ref<number>(defaultValue);
    // 更改音量
    const changeVolume = (value: number, flag = false) => {
      const validVal = Number((value / 100).toFixed(2));
      gsSdkService?.submitVolume(validVal);
      if(!flag){
        setItem('local', key, value)
      }
      gsSdkService.submitMute(validVal <= 0)
    };
    gsSdkService?.on("onDataReady", function (event) {
      changeVolume(defaultValue);
    });

    function toggleMute(){
      if(sliderValue.value > 0){
        changeVolume(0, true)
        sliderValue.value = 0
      }else{
        let value = +getItem('local', key)
        if(value <= 0) value = DEF_VOLUME
        changeVolume(value, true)
        sliderValue.value = value
      }
    }
    return {
      sliderValue,
      changeVolume,
      toggleMute
    };
  },
};
