
import { defineComponent } from "vue";
import Volume from "./components/Volume.vue";
import PlayControl from "./components/PlayControl.vue";
import PlayTime from "./components/PlayTime.vue";
import VideoProgress from "./components/VideoProgress.vue";
import VideoPlayRate from "./components/VideoPlayRate.vue";
import FullScreen from "./components/FullScreen.vue";
import ChangeView from "./components/ChangeView.vue";
import ChangeNetwork from "./components/ChangeNetwork.vue";
import ChangePlayer from "./components/ChangePlayer.vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";
import {Mode} from '@/core/constant'
import LiveStream from "./components/LiveStream.vue";
// import HandsUp from "./components/HandsUp.vue";
import Like from "./components/Like.vue";

export default defineComponent({
  name: "VideoTools",
  components: {
    Volume,
    PlayControl,
    PlayTime,
    VideoProgress,
    VideoPlayRate,
    FullScreen,
    ChangeView,
    ChangeNetwork,
    ChangePlayer,
    LiveStream,
    // HandsUp,
    Like
  },
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    return {
      isLive: liveService.mode.value === Mode.live
    };
  },
});
