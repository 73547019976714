
import { defineComponent, onMounted, ref, watch } from "vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { IObject } from "@/core/interface/base";

export default defineComponent({
  name: "GsVideoLive",
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    const containerRef = ref<HTMLDivElement>();
    onMounted(() => {
      const container = containerRef.value;
      if (container) {
        const selector =
          liveService.mode.value === "live" ? "gs:video-live" : "gs:video-vod";
        const atrrs: IObject = {
          ownerid: liveService.ownerid.value,
          authcode: liveService.token.value,
          uname: liveService.username.value,
          uid: liveService.uid.value,
          btnimg: require("@/assets/images/icon_big_play.png"),
          gsver: liveService.player === "h5" ? "2" : "",
        };
        const insertEle = createElement(selector, atrrs);
        container.appendChild(insertEle);
        // gssdk内部方法，触发gs标签加载。gssdk.js默认是autoLoad，gssdk.js如果先于gs标签加载完毕，会导致无法播放视频
        window.GS._open_.loadSDKTags()
      }
    });

    function createElement(selector: string, atrrs: IObject): Element {
      const namespace = "http://www.gensee.com/ec";
      const ele = document.createElementNS(namespace, selector);
      ele.setAttribute("site", liveService.site);
      ele.setAttribute("ctx", "webcast");
      ele.setAttribute("bar", "false");
      for (const atrr in atrrs) {
        ele.setAttribute(atrr, atrrs[atrr]);
      }
      return ele;
    }
    return {
      containerRef,
    };
  },
});
