
import { ref, computed } from "vue";
import gsUtil from "@/core/utils/gensee";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default {
  name: "PlayTime",
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const playNow = ref<number>(0);
    const duration = ref<number>(0);

    gsSdkService?.on("onFileDuration", function (event) {
      const _duration = event.data.duration;
      duration.value = Number(_duration / 1000);
    });
    // 当前播放时间
    gsSdkService?.on("onPlayheadTime", function (event) {
      const playheadTime = event.data.playheadTime;
      // console.log(gsUtil.timeDuration(playheadTime/ 1000));
      playNow.value = Number(playheadTime / 1000);
    });

    const playNowFormat = computed(() => {
      return gsUtil.timeDuration(playNow.value);
    });

    const durationFormat = computed(() => {
      return gsUtil.timeDuration(duration.value);
    });

    return {
      playNow,
      duration,
      playNowFormat,
      durationFormat,
    };
  },
};
