<template>
  <div
    class="wrapper"
    xmlns:gs="http://www.gensee.com/ec"
    ref="containerRef"
  ></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default defineComponent({
  name: "GsDoc",
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    const containerRef = ref<HTMLDivElement>();
    onMounted(() => {
      const container = containerRef.value;
      if (container) {
        const namespace = "http://www.gensee.com/ec";
        const selector = "gs:doc";
        const ownerid = liveService.ownerid.value;
        const ele = document.createElementNS(namespace, selector);
        ele.setAttribute("site", liveService.site);
        ele.setAttribute("ctx", "webcast");
        ele.setAttribute("gsver", liveService.player === "h5" ? "2" : "");
        ele.setAttribute("ownerid", ownerid);
        container.appendChild(ele);
        window.GS._open_.loadSDKTags()
      }
    });
    return {
      containerRef,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
</style>
