
export type PalyControlStatus = true;
export type PauseControlStatus = false;
export type ControlStatus = PalyControlStatus | PauseControlStatus;

import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { LiveServiceKey } from '@/services/live.service';

export default {
  name: "PlayControl",
  setup() {
    const liveServie = useRequiredInject(LiveServiceKey);
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const controlStatus = ref<ControlStatus>(false);
    const changeControlStatus = (): any => {
      controlStatus.value = !controlStatus.value;
      if (controlStatus.value) {
        gsSdkService?.play();
      } else {
        gsSdkService?.pause();
      }
    };
    // 初始化点播，点播开始
    gsSdkService?.on("loadStart", function (event) {
      controlStatus.value = false;
      if (liveServie.mode.value === "vod") {
        gsSdkService.rollbackSeekHistory(liveServie.ownerid.value);
      }
    });

    gsSdkService?.on("onPause", function (event) {
      controlStatus.value = false;
    });

    gsSdkService?.on("onPlay", function (event) {
      controlStatus.value = true;
    });

    gsSdkService?.on("onStop", function (event) {
      controlStatus.value = false;
    });

    return {
      controlStatus,
      changeControlStatus,
    };
  },
};
