<template>
  <div class="list" v-if="!loading && !isEmpty()">
    <div
      class="list-item"
      v-for="taskItem in tasks"
      :key="taskItem.taskId"
      @click="goToTaskPage(taskItem)"
    >
      <div class="task-title">
        {{ taskItem.taskName }}
      </div>
      <div class="detail-wrapper">
        <div>
          <span class="tag">{{ getTaskName(taskItem.taskType) }}</span>
          <span class="sub-title">{{ taskItem.captionTitle }}</span>
        </div>

        <div v-if="taskItem.status === '1'">
          <CheckCircleFilled class="checked" />
        </div>
      </div>
    </div>
    <div class="action-wrapper">
      <a-button  class="action-btn" type="primary" @click="getTask">获取最新任务</a-button>
    </div>
  </div>

  <div class="loading-wrapper" v-if="loading">
    <a-spin />
  </div>
  <div class="empty-wrapper" v-if="!loading && isEmpty() && hadClickGetButton">
    <a-empty :description="'暂无任务'" />
  </div>
  <div class="action-wrapper action-wrapper2" v-if="!loading && isEmpty()" ref="emptyWrapper">
    <a-button  class="action-btn" type="primary" @click="getTask(true)">获取最新任务</a-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import CheckCircleFilled from "@ant-design/icons-vue/CheckCircleFilled";
import { getTaskList } from "@/api/live.api";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";

export interface ITask {
  taskType: string;
  taskName: string;
  taskId: string;
  captionTitle: string;
  url: string;
  status: string;
}

export default defineComponent({
  name: "Tasks",
  components: {
    CheckCircleFilled,
  },
  setup() {
    const liveServie = useRequiredInject(LiveServiceKey);
    const tasks = ref<ITask[]>([]);
    const loading = ref(false);
    const hadClickGetButton = ref(false)//是否已经点击了获取按钮
    const getTaskName = (taskType: string) => {
      /*
    阅读任务(0)、案例任务(1)、作业任务(2)、实训任务(3)、考试任务(4)、实战任务(5)、问卷(6)、测评(7)
    * */
      switch (Number(taskType)) {
        case 0:
          return "阅读";
        case 1:
          return "案例";
        case 2:
          return "作业";
        case 3:
          return "实训";
        case 4:
          return "考试";
        case 5:
          return "实战";
        case 6:
          return "问卷";
        case 7:
          return "测评";
        default:
          return "--";
      }
    };
    // 获取任务列表
    function getTask(action?: boolean): void {
      loading.value = true;
      getTaskList(
        liveServie.lessonTimeId.value,
        liveServie.orgCode,
        liveServie.studentId
      )
        .then((res) => {
          loading.value = false;
          if (res.status === 200) {
            tasks.value = res.data || [];
          }
          if(action){
            hadClickGetButton.value = true
            setTimeout(() => {
              hadClickGetButton.value = false
            }, 2e3);
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }

    function goToTaskPage(task: ITask) {
      window.open(task.url, "_blank");
    }
    function isEmpty(): boolean {
      return tasks.value.length === 0;
    }

    getTask();

    return {
      tasks,
      getTaskName,
      goToTaskPage,
      isEmpty,
      loading,
      getTask,
      hadClickGetButton
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.list {
  width: 100%;
  height: 100%;
  padding: 18px;
  overflow: auto;
  .list-item {
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 12px;
    margin-bottom: 18px;
    cursor: pointer;

    .task-title {
      font-size: 14px;
      color: #3a3c42;
      word-break: break-word;
    }

    .detail-wrapper {
      display: flex;
      justify-content: space-between;

      .tag {
        display: inline-block;
        padding: 0 4px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        margin-right: 18px;

        font-size: 12px;
        color: #00ab84;
        background-color: rgba(0, 171, 132, 0.1);
        border-radius: 3px;
      }

      .sub-title {
        display: inline-block;
        color: #a3abb8;
        font-size: 12px;
      }

      .checked {
        font-size: 18px;
        color: #00ab84;
      }
    }
  }

  .selected {
    border: 2px solid #00ab84;
  }
}
.action-wrapper {
  text-align: center;
  .action-btn {
    font-size: 14px;
    border-radius: 20px;
  }
  &2{
    margin-top: 55px;
  }
}
.loading-wrapper {
  width: 100%;
  text-align: center;
  padding: 18px;
}

.empty-wrapper {
  width: 100%;
  text-align: center;
  padding: 18px;
}
</style>
