
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { MGensee } from "@/core/interface/gensee.d";
import useRequiredInject from "@/composable/useRequiredInject";
import WifiOutlined from '@ant-design/icons-vue/WifiOutlined'

export default {
  name: "ChangeNetwork",
  components: {
    WifiOutlined
  },
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const selectedNetwork = ref('');
    const chooseNetwork = (item: MGensee.INetSettings) => {
      selectedNetwork.value = item.label;
      gsSdkService.submitNetChoice(item.label);
      closePopover();
    };

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      netSettings: gsSdkService.netSettings,
      selectedNetwork,
      chooseNetwork,
      isshow,
    };
  },
};
