import { Json } from '@/core/interface/base';
import { ToolsUtil } from './tools.util'
import { codeJsonAES } from './crypto'

export class LocalStorageUtil {
  static cacheUser: Json = {};
  static getUser (): Json {
    if (!LocalStorageUtil.cacheUser.token) {
      const key = ToolsUtil.createCacheKey('USER_INFO')
      const str = localStorage.getItem(key)
      let userInfo: any = {}
      if (str) {
        // 开发不走解密处理
        // if (process.env.VUE_APP_environment === 'development') {
        //   userInfo = JSON.parse(str)
        // } else {
          userInfo = codeJsonAES(JSON.parse(str), 'decode')
        // }
      }
      if (userInfo.token) {
        LocalStorageUtil.cacheUser = userInfo
      }
      return userInfo
    } else {
      return LocalStorageUtil.cacheUser
    }
  }


  static getUserId (): string| undefined {
    return this.getUser().id
  }

}
