
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useRequiredInject from '@/composable/useRequiredInject';

export default {
  name: "ChangeView",
  setup() {
    const videoToolsService = useRequiredInject(VideoToolsServiceKey);
    const changeView = () => {
      videoToolsService.changeViewRef.value =
        videoToolsService.changeViewRef.value === "doc" ? "video" : "doc";
    };
    return {
      changeView,
    };
  },
};
