import { LocalStorageUtil } from "@/common/utils/localstorage.util";
import { ToolsUtil } from "@/common/utils/tools.util";
import Axios from "@/core/http/request";
import { AxiosInstance } from "axios";
// api后台对接人潘锡志
/**
 * 定制直播间任务列表
 * @param timeId 课时ID
 * @param orgCode 赛道
 * @param studentId 学生ID
 * @returns  {AxiosInstance}
 * @api http://yapi.hqbis.com/project/46/interface/api/6098
 */
export function getTaskList(timeId: string, orgCode: string, studentId: string) {
  const url = "tsk/dzRoom/taskList";
  const params = { timeId, orgCode, studentId };
  return Axios.get(url,{ params });
}
/**
 * 获取回放列表
 * @param lessonPlanId 排课计划ID
 * @param studentId 学生ID
 * @returns {AxiosInstance}
 * @api http://yapi.hqbis.com/project/46/interface/api/6102
 */
export function getRecordList(lessonPlanId: string, studentId: string) {
  const url = "tsk/dzRoom/recordList";
  const params = { lessonPlanId, studentId };
  return Axios.get(url,{ params });
}
/**
 * 获取直播间相关信息
 * @param type 
 * @param lessonTimeId 
 * @param headers 
 * @returns {AxiosInstance}
 * @api http://yapi.hqbis.com/project/46/interface/api/19866 
 */
export function getLiveRoomInfo(type: string = '', lessonTimeId: string = '') {
  const url = "tsk/dzRoom/getLiveRoomInfo";
  const params = { lessonTimeId, type, ignore: 1 };
  const headers = {
    orgCode: ToolsUtil.getOrgCode() || '',
    stuId: LocalStorageUtil.getUserId() || ''
  }
  return Axios.get(url,{ params, withCredentials: true, headers });
}