/**
 * 获取url query 参数
 */
export function getQueryParams() {
  const search = window.location.search.substring(1);
  const pairs = search.split("&");
  const params = new Map<string, string>();
  for (const pair of pairs) {
    const parts = pair.split("=");
    params.set(decodeURIComponent(parts[0]), decodeURIComponent(parts[1]));
  }
  return params;
}

/**
 * 替换或添加链接query的参数
 * @param oUrl 
 * @param paramName 
 * @param replaceWith 
 * @returns 
 */
export function replaceParamVal(oUrl: string, paramName: string, replaceWith: string): string {
  let nUrl = '';
  if (oUrl.includes(`${paramName}=`)) {
    const re = eval('/('+ paramName+'=)([^&]*)/gi');
    nUrl = oUrl.replace(re, paramName + '=' + replaceWith);
  } else {
    if (oUrl.includes("?")) {
      nUrl = `${oUrl}&${paramName}=${replaceWith}`;
    } else {
      nUrl = `${oUrl}?${paramName}=${replaceWith}`;
    }
  }
  return nUrl;
}
