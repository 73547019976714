import { getLiveRoomInfo } from '@/api/live.api'
import { GsSdkService, GsSdkServiceKey } from '@/services/gs-sdk.service'
import { LiveServiceKey, LiveService } from '@/services/live.service'
import { VideoToolsServiceKey, VideoToolsService } from '@/services/video-tools.service'
import { createRouter, createWebHistory } from 'vue-router'
import Live from '@/pages/Live.vue'

const routes = [
  { path: '/live', component: Live },
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) =>{
  const res = await getLiveRoomInfo(to.query.mode as string, to.query.lessonTimeId as string)
  if(res.status === 200){
    // @ts-ignore
    window.app.provide(LiveServiceKey, new LiveService(res.data));
     // @ts-ignore
    window.app.provide(VideoToolsServiceKey, new VideoToolsService());
     // @ts-ignore
    window.app.provide(GsSdkServiceKey, new GsSdkService());
  }
  next()
})

export {
  router
}