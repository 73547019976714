/**
 * 模式
 */
export enum Mode{
  live= 'live',
  vod = 'vod'
}
/**
 * 聊天角色。组织者=1 主讲=2 嘉宾=4 普通参加者=8 Web参加者=16
 * 
 * @see http://www.gensee.com/doc_b8bd276dc20411e8929d6b39db9e86c6.html?pos=toc-85
 */
export enum Role{
  organizer = 1,
  speaker = 2,
  honoured = 4,
  ordinaryParticipant = 8,
  webParticipant = 16
}

export default {
  Mode,
  Role
}