<template>
  <div class="tools-wrapper">
    <div v-if="!isLive">
      <VideoProgress />
    </div>
    <div class="control-wrapper">
      <div class="control-group-left">
        <template v-if="!isLive">
          <PlayControl  />
          <PlayTime class="space2" />
        </template>
        <Volume/>
        <ChangeView class="space" />
        <ChangeNetwork v-if="isLive" class="space" />
        <ChangePlayer class="space" />
        <LiveStream v-if="isLive" class="space" />
        <VideoPlayRate v-else-if="!isLive" class="space" />
      </div>

      <div class="control-group-right">
          <!-- <HandsUp v-if="isLive" class="space"/> -->
          <Like v-if="isLive" class="space"/>
          <FullScreen class="space"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Volume from "./components/Volume.vue";
import PlayControl from "./components/PlayControl.vue";
import PlayTime from "./components/PlayTime.vue";
import VideoProgress from "./components/VideoProgress.vue";
import VideoPlayRate from "./components/VideoPlayRate.vue";
import FullScreen from "./components/FullScreen.vue";
import ChangeView from "./components/ChangeView.vue";
import ChangeNetwork from "./components/ChangeNetwork.vue";
import ChangePlayer from "./components/ChangePlayer.vue";
import { LiveServiceKey } from "@/services/live.service";
import useRequiredInject from "@/composable/useRequiredInject";
import {Mode} from '@/core/constant'
import LiveStream from "./components/LiveStream.vue";
// import HandsUp from "./components/HandsUp.vue";
import Like from "./components/Like.vue";

export default defineComponent({
  name: "VideoTools",
  components: {
    Volume,
    PlayControl,
    PlayTime,
    VideoProgress,
    VideoPlayRate,
    FullScreen,
    ChangeView,
    ChangeNetwork,
    ChangePlayer,
    LiveStream,
    // HandsUp,
    Like
  },
  setup() {
    const liveService = useRequiredInject(LiveServiceKey);
    return {
      isLive: liveService.mode.value === Mode.live
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.tools-wrapper {
  width: 100%;
  height: 54px;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;

  .control-wrapper {
    display: flex;
    justify-content: space-between;
    flex: auto;

    padding: 0 36px;

    .space {
      margin-left: 24px;
    }
    .space2{
      margin-left: 10px;
      margin-right: 24px;
    }

    .control-group-left {
      display: flex;
    }

    .control-group-right {
      display: flex;
    }
  }
}
</style>
