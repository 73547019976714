import { ref, watch } from "vue";
import { VideoToolsServiceKey } from "@/services/video-tools.service";
import useRequiredInject from "./useRequiredInject";

export default function useDetectMouseMove() {
  const videoToolsService = useRequiredInject(VideoToolsServiceKey);
  const mouseContainerRef = ref<HTMLDivElement>();
  const mouseIdle = ref(true);
  let timer: number;
  const clearTimerHandler = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };
  const mouseMoveHandler = function () {
    mouseIdle.value = false;
    clearTimerHandler();
    timer = setTimeout(() => {
      mouseIdle.value = true;
    }, 5000);
  };
  watch(videoToolsService.fullscreenRef, (newVal, oldVal) => {
    const mouseContainerEle = mouseContainerRef.value;
    if (!mouseContainerEle) return;
    if (newVal) {
      mouseContainerEle.addEventListener("mousemove", mouseMoveHandler);
    } else {
      mouseContainerEle.removeEventListener("mousemove", mouseMoveHandler);
      clearTimerHandler();
    }
  });
  return {
    mouseContainerRef,
    mouseIdle,
  };
}
