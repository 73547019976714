<template>
  <div class="list" ref="listContentTpl">
    <div class="list-item" v-for="chatItem in chatRecords" :key="chatItem.id">
      <div class="detail-wrapper">
        <div style="margin-bottom: 6px">
          <a-avatar :size="24" :src="chatItem.isMe ? avatar : chatItem.senderRole.includes(Role.speaker)? teacherImg: visitorImg">
            <template #icon><UserOutlined /></template>
          </a-avatar>
          <span class="user-name">
            {{ chatItem.username }}
          </span>
          <span class="user-name" v-if="chatItem.isMe">
            (我)
          </span>
        </div>

        <div>
          <span class="short-time">{{ chatItem.chatTime }}</span>
        </div>
      </div>

      <div
        :class="{ 'chat-content': true, 'chat-content-me': chatItem.isMe }"
        spellcheck="false"
        v-html="chatItem.chatContent"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick } from "vue";
import UserOutlined from "@ant-design/icons-vue/UserOutlined";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import gsUtil, { emotionText2Local } from "@/core/utils/gensee";
import {
  IChatRecord,
  ChatRecordServiceKey,
  IChatEvent,
} from "../services/chat-record.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { LiveServiceKey } from "@/services/live.service";
import { MGensee } from "@/core/interface/gensee.d";
import { Role } from "@/core/constant";
const teacherImg = require('@/assets/images/teacher.png')
const visitorImg = require('@/assets/images/visitor.png')

export default defineComponent({
  name: "ChatList",
  components: {
    UserOutlined,
  },
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const chatRecordService = useRequiredInject(ChatRecordServiceKey);
    const liveService = useRequiredInject(LiveServiceKey);
    const isVod = liveService.mode.value === 'vod'
    // 收到系统消息
    gsSdkService?.on("onMessage", function (event) {
      console.log(event);
    });
    // 收到公聊消息
    gsSdkService?.on("onPublicChat", function (event) {
      // console.log('[onPublicChat]', event);
      const { id, senderUid, sender, richtext, submitTime,  utctime, senderRole =''} = event.data;
      // const chatContent = emotion2Local(richtext);
      const chatContent = richtext;
      const chatItem: IChatRecord = {
        id,
        username: sender,
        chatTime: gsUtil.formateUnix( parseInt(submitTime || utctime || (Date.now() / 1e3))),
        chatContent,
        isMe: liveService.uid.value === senderUid,
        senderRole
      };
      chatRecordService.addChatRecord(chatItem);
      nextTick(()=>{
        chatRecordService.chatListScroll();
      })
    });
    // 收到私聊消息
    gsSdkService?.on("onPriChat", function (event) {

    });
    if(isVod){
      gsSdkService.on('onDataReady', ()=>{
        console.log('[vod data ready]');
        /**回放聊天记录需要跟随播放时间点展示该时间点发生的聊天，需要以下两个回调协同处理**/
        // 聊天记录回调
        gsSdkService.on('onChat', event =>{
          // console.log('[onChat]', event); 
          chatRecordService.updateChatRecordRemoveSame(formatChatList(event))
        })
        // 聊天记录片段回调
        gsSdkService.on('onChatSegmentList', event =>{
          // console.log('[onChatSegmentList]', event.data, formatChatList(event));
          chatRecordService.updateChatRecord(formatChatList(event))
          // 表示还有更多聊天片段
          if(event.data.more){
            gsSdkService.submitChatSegment()
          }
        })
        // 开启聊天同步，这样才会有聊天记录随播放时间点回显
        gsSdkService.setupChatSync(true)
        gsSdkService.submitChatSegment()
        
      })
    }else{
      // 直播下回调所有聊天记录
      gsSdkService?.on("onChatHistory", function (event: MGensee.IGsEvent) {      
        chatRecordService.addChatRecord(...formatChatList(event));
        chatRecordService.chatListScroll();
      });

    }
    // 把展示互动回调回的聊天记录二次封装
    function formatChatList(event: MGensee.IGsEvent){
      const chatList: any[] = event.data.list;
      const formatChatList: IChatRecord[] = chatList.map((chatItem) => {
        return {
          id: chatItem.id || chatItem.senderId,
          username: chatItem.sender,
          chatTime: gsUtil.formatTime(chatItem.submitTime * 1000),
          chatContent: emotionText2Local(chatItem.content),
          isMe: String(liveService.uid.value) === String(chatItem.senderId),
          senderRole: chatItem.senderRole || '',
          submitTime: chatItem.submitTime
        }
      });
      return formatChatList
    }
    const chatRecords = computed(()=>{
      const chatRecords = chatRecordService.chatRecords
      return liveService.seeMe.value ? chatRecords.filter(item => {
        if(item.isMe) return item.isMe
        if(item.senderRole){
          return item.senderRole.includes(Role.speaker)
        }
        return false
      }) : chatRecords
    })
    return {
      listContentTpl: chatRecordService.listContentTpl,
      chatRecords: chatRecords,
      avatar: liveService.avatar.value,
      teacherImg,
      visitorImg,
      Role
    };
  },
});
</script>

<style lang="less" scoped>
.list {
  padding: 18px;
  flex: auto;
  overflow-x: hidden;
  overflow-y: auto;

  .list-item {
    margin-bottom: 18px;

    .chat-content {
      font-size: 14px;
      color: #6c7179;
      word-break: break-all;
    }

    .chat-content-me {
      font-size: 14px;
      color: #00ab84;
    }

    .detail-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .user-name {
        display: inline-block;
        color: #a3abb8;
        font-size: 14px;
        margin-left: 6px;
      }

      .short-time {
        font-size: 12px;
        color: #a3abb8;
      }
    }
  }

  .selected {
    border: 2px solid #00ab84;
  }
}
</style>
