
import { defineComponent, nextTick, provide } from "vue";
import gsUtil, { emotionNormalize } from "@/core/utils/gensee";
import { message } from "ant-design-vue";

import ChatInput from "./components/ChatInput.vue";
import ChatList from "./components/ChatList.vue";
import { LiveServiceKey } from "@/services/live.service";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import {
  ChatRecordService,
  IChatRecord,
  ChatRecordServiceKey,
} from "./services/chat-record.service";
import useRequiredInject from "@/composable/useRequiredInject";

export default defineComponent({
  name: "ChatRecord",
  components: {
    ChatList,
    ChatInput,
  },
  setup() {
    const chatRecordService = new ChatRecordService();
    provide(ChatRecordServiceKey, chatRecordService);

    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const liveService = useRequiredInject(LiveServiceKey);

    const sendContent = (html: string, innerText: string, cb?: () => void) => {
      html = html.replace(/ /gi, " ").replace(/\s+/g, " ");
      if (gsUtil.isEmpty(html) || html === "<br>" || html === "<br/>" || (!gsUtil.trim(innerText.replace(/\n/gi, '')) && !/img/gi.test(html)) ) {
        message.info({
          content: "请输入聊天内容",
        });
        return;
      }
      const chatItem: IChatRecord = {
        id: Date.now().toString(),
        username: liveService.username.value || '我',
        chatTime: gsUtil.formatTime(Date.now()),
        chatContent: html,
        isMe: true
      };
      chatRecordService.addChatRecord(chatItem);
      const richtext = emotionNormalize(html, false);
      const text = emotionNormalize(html, true)
        .replace(/<br>/gi, "")
        .replace(/[\r\n]/g, "")
        .replace(/<\/?.+?>/g, "");

      const params = {
        content: text,
        richtext: richtext,
        security: "high",
      };
      gsSdkService?.submitChat(params, cb);
    };

    const chatInputChange = (content: string, innerText: string) => {
      sendContent(content, innerText, () => {
        chatRecordService.clearChatContent();
        chatRecordService.editorChange();
        chatRecordService.focusChatContent();
        nextTick(()=> chatRecordService.chatListScroll())
      });
    };

    return {
      mode: liveService.mode,
      chatInputChange,
    };
  },
});
