
import { defineComponent, ref } from "vue";
import EmotionTool from "./EmotionTool.vue";
// import RegularSpeech from "./RegularSpeech.vue";
import { ChatRecordServiceKey } from "../services/chat-record.service";
import useRequiredInject from "@/composable/useRequiredInject";
import { LiveServiceKey } from "@/services/live.service";
const xss = require("xss");
const xssFilterAllHtml = new xss.FilterXSS({
  css: false,
  whiteList: [],
  stripIgnoreTag: true
})
export default defineComponent({
  name: "ChatInput",
  components: {
    EmotionTool,
    // RegularSpeech,
  },
  setup(props, { emit }) {
    const liveService = useRequiredInject(LiveServiceKey);
    const chatRecordService = useRequiredInject(ChatRecordServiceKey);
    const seeMe = ref(liveService.seeMe.value)
    const contentChange = (content: string, innerText: string) => emit("contentChange", content, innerText);
    const sendChatContent = () => {
      const divEle = chatRecordService.chatContentTpl.value;
      if (divEle) {
        const html = divEle.innerHTML;
        contentChange(html, divEle.innerText);
      }
    };

    // 光标处插入内容
    function insertHtmlByRange(divEle: HTMLDivElement, insertEle: any): void {
      divEle?.focus();
      let sel: any, range: any;
      const doc: any = document;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();
          range.insertNode(insertEle);
          range.collapse(false); // 将选取合并
        }
      } else if (doc.selection && doc.selection.createRange) {
        doc.selection.createRange().text = insertEle;
      }
    }
    function insertHtml(html: string) {
      let selection = window.getSelection() as Selection
      let range = selection.getRangeAt(0)
      if (selection.rangeCount) {
        let el = document.createElement("div");
        el.innerHTML = html;
        let frag = document.createDocumentFragment(), node, lastNode;
        while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
        }
      }
    }

    // 选择表情
    const chooseEmotion = (e: Event) => {
      const emotion = (e.target as HTMLImageElement).cloneNode();
      const divEle = chatRecordService.chatContentTpl.value;
      if (divEle) {
        insertHtmlByRange(divEle, emotion);
      }
      chatRecordService.editorChange();
    };

    const chooseSpeech = (speech: string) => {
      contentChange(speech, speech);
    };

    const editorChange = () => {
      chatRecordService.editorChange();
    };
    function toggleSeeMe(){
      seeMe.value = !seeMe.value
      liveService.seeMe.value = seeMe.value
    }
    function onTextareaPaste(e: Event){
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignored
        let paste = (e.clipboardData || window.clipboardData).getData('text/plain')
        if(paste){
            insertHtml(xssFilterAllHtml.process(paste))
            editorChange()
        }
    }

    return {
      chatContentTpl: chatRecordService.chatContentTpl,
      hasContent: chatRecordService.hasContent,
      seeMe,
      sendChatContent,
      editorChange,
      chooseEmotion,
      chooseSpeech,
      toggleSeeMe,
      onTextareaPaste
    };
  },
});
