<template>
  <div class="change-newwork-wrapper">
    <a-popover
      color="#343434"
      overlayClassName="change-network-popover"
      trigger="click"
      v-model:visible="isshow"
    >
      <WifiOutlined  class="icon-network"/>
      <template #content>
        <div class="network-list">
          <div
            v-for="(item, index) in netSettings"
            :key="index"
            class="network-item"
            :class="{selected: item.label === selectedNetwork || item.selected}"
            @click="chooseNetwork(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { GsSdkServiceKey } from "@/services/gs-sdk.service";
import { MGensee } from "@/core/interface/gensee.d";
import useRequiredInject from "@/composable/useRequiredInject";
import WifiOutlined from '@ant-design/icons-vue/WifiOutlined'

export default {
  name: "ChangeNetwork",
  components: {
    WifiOutlined
  },
  setup() {
    const gsSdkService = useRequiredInject(GsSdkServiceKey);
    const selectedNetwork = ref('');
    const chooseNetwork = (item: MGensee.INetSettings) => {
      selectedNetwork.value = item.label;
      gsSdkService.submitNetChoice(item.label);
      closePopover();
    };

    const isshow = ref(false);
    const closePopover = () => {
      isshow.value = false;
    };
    return {
      netSettings: gsSdkService.netSettings,
      selectedNetwork,
      chooseNetwork,
      isshow,
    };
  },
};
</script>

<style scoped lang="less">
.change-newwork-wrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .icon-network {
    font-size: 18px;
    color:#ffffff;

    &:hover {
      color: #00ab84;
    }
  }
}
</style>

<style lang="less">
.change-network-popover {
  .ant-popover-content .ant-popover-arrow {
    border-right-color: #343434 !important;
    border-bottom-color: #343434 !important;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .network-list {
    padding: 5px 0;
    color: #ffffff;
    font-size: 14px;

    .network-item {
      // width: 300px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      padding: 0 5px;
      cursor: pointer;

      &:hover {
        color: #00ab84;
        background-color: #434343;
      }
    }

    .selected {
      color: #00ab84;
    }
  }
}
</style>
