import {Json} from '@/core/interface/base'

export class ToolsUtil {

  static cacheOrgCode = '';
  /**
   * 从符合?xx&或?xx&v.里拿orgCode,注意符合?第一位加&,此外，切记不要只加=,即？qkc=拿到的是cjsd
   * /?qkc#/ /?qkc&aa=1#/ /#/?qkc /#/?qkc&aa=1 四种情况拿到qkc
   * /#/ 拿到cjsd
   * /?aa=1&qkc&bb=2#/ /#/?aa=1&qkc&bb=2 为了避免用户误删查询字符串导致拿错,这两种拿到cjsd
   * 不符合一律拿到cjsd
   */
  static getOrgCodeFromQuestionMark = () => {
    const hrefs = window.location.href.split('?')
    if (hrefs.length >= 2) {
      const paramStr = hrefs[1]
      let orgCodeEnd = hrefs[2]// hash后的?之后
      // 参数字符串里有没带 #&
      let orgCodePre = paramStr.split('#')[0] // hash前的?和#之间
      const test = /^[a-z]+$/
      if (orgCodePre) {
        if (test.test(orgCodePre)) {
          return orgCodePre
        } else {
          orgCodePre = orgCodePre.split('&')[0]
          if (test.test(orgCodePre)) {
            return orgCodePre
          } else {
            orgCodePre = orgCodePre.split('=v.')[0]
            if (test.test(orgCodePre)) {
              return orgCodePre
            }
          }
        }
      }
      if (orgCodeEnd) {
        if (test.test(orgCodeEnd)) {
          return orgCodeEnd
        } else {
          orgCodeEnd = orgCodeEnd.split('&')[0]
          if (test.test(orgCodeEnd)) {
            return orgCodeEnd
          } else {
            orgCodeEnd = orgCodeEnd.split('=v.')[0]
            if (test.test(orgCodeEnd)) {
              return orgCodeEnd
            }
          }
        }
      }
      return 'cjsd'
    } else {
      return 'cjsd'
    }
  }

  static createCacheKey (prefix: string) {
    return `__STU__${prefix}_${ToolsUtil.getOrgCode()}`
  }

  static getTwoWords (str: string) {
    if (str && str.replace(/[\u4e00-\u9fa5]/g, 'aa').length > 60) {
      return str.substring(0, 50) + '...'
    } else {
      return str || '--'
    }
  }

  static getMaxSeq (arr: Json) {
    if (!arr) {
      return 0
    }
    let max = 0
    arr.forEach((item:Json) => {
      if (item.seq > max) {
        max = item.seq
      }
    })
    return max + 1
  }

  static watchTool = (watchObj = {}, watchKey = 'flag', initState = false, callback = (val: any) => val) => {
    let watchFlag = initState
    Object.defineProperty(watchObj, watchKey, {
      get () {
        return watchFlag
      },
      set (val) {
        watchFlag = val
        callback(val)
      }
    })
  };

  /**
   * 从子域名拿orgCode， qkc.xxxx.com
   */
  static getOrgCodeFromSubDN = () => {
    return location.host.split('.')[0]
  };

  static getOrgCode = () => {
    if (ToolsUtil.cacheOrgCode !== '') {
      return ToolsUtil.cacheOrgCode
    }
    const org = ToolsUtil.getOrgCodeFromQuestionMark()
    if (org === '') {
      ToolsUtil.cacheOrgCode = 'cjsd'
    } else {
      ToolsUtil.cacheOrgCode = org
    }

    console.log(ToolsUtil.cacheOrgCode)

    return ToolsUtil.cacheOrgCode
  }
}